import customerPhoto1 from "assets/images/home/testimonialImage1.png";
import customerPhoto2 from "assets/images/home/testimonialImage2.png";

export const customerStoriesData = [
  {
    image: customerPhoto1,
    industry: "Pharmaceutical",
    content: (
      <>
        I would <span> definitely recommend </span> other businesses to apply
        for <span> Recurring Revenue Financing,</span> as it is the best way to
        extend your runway & address immediate spends. It is best suited for
        short timelines, ideally 6 to 12 months & this makes it perfect for
        increasing your valuation for upcoming funding rounds.
      </>
    ),

    button: ""
  },
  {
    image: customerPhoto2,
    industry: "Construction",
    content: (
      <>
        <span>
          B2B marketplaces can really benefit from CrediLinq’s PayLater
          solution.
        </span>{" "}
        {""}
        Juragan Material has embedded CrediLinq’s B2B PayLater solution in our
        marketplace and it is enabling us to focus on our core business, which
        is the construction materials marketplace and to focus less on providing
        financing from our own balance sheet. This way, Juragan Material has
        scalability for growth.
      </>
    ),
    button: ""
  }
];
