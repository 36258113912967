import "components/AreYouEligible/areYouEligible.scss";

const AreYouEligible = () => {
  return (
    <section className="commonBackground areYouEligible">
      <div className="container">
        <h2 className="commonHeading ">
          {" "}
          <span className="eligibilityTextGradient">Are You Eligible?</span>
          <br />
          <span className="small">
            Review the Criteria to Ensure Your Application is Eligible
          </span>
        </h2>
        <div className="criteriaBoxWrap">
          <div className="criteriaBox">
            <div className="step">
              <span>1</span>
            </div>
            <h3>Business Vintage</h3>
            <p>
              Your business has been operational for a{" "}
              <strong>minimum of one year</strong>
            </p>
          </div>
          <div className="criteriaBox">
            <div className="step">
              <span>2</span>
            </div>
            <h3>Active Tiktok Shop</h3>
            <p>
              You have been selling on your TikTok shop for{" "}
              <strong>atleast 3 months with active transactions</strong>
            </p>
          </div>
          <div className="criteriaBox">
            <div className="step">
              <span>3</span>
            </div>
            <h3>Operating Turnover</h3>
            <p>
              You have a monthly average operating turnover of{" "}
              <strong>USD$10,000 or more</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreYouEligible;
