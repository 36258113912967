import Gif1 from "assets/images/home/B2BPayLater.gif";
import Gif2 from "assets/images/home/GMV.gif";
import Gif3 from "assets/images/header/bg.png";

export const homeBannerData = [
  {
    title1: "Drive Your B2B Sales With Our ",
    title2: " Embedded Finance Solutions",
    content:
      "CrediLinq APIs enable businesses and banks to build financial products for merchants and sellers.",

    img: Gif1,
    link: "/get-started"
  },
  {
    title1: "Empower Platform Sellers With Our Embedded & ",
    title2: "Merchant Financing Solutions.",
    content:
      "Build seller financing solutions by integrating with our API stack",

    img: Gif2,
    link: "/get-started"
  },
  {
    title1: "CrediLinq Is Now A",
    title2: "Certified Amazon Selling Partner For Credit",
    img: Gif3,
    link: "/seller-financing"
  },
  {
    index: 3
  },
  {
    index: 4
  }
];
