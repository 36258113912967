import React from "react";

import goldenStar from "assets/images/amazonSeller/goldStar.svg";
import silverStar from "assets/images/amazonSeller/silverStar.svg";
// import downArrow from "assets/images/headerFromCustomer/gradientDownArrow.svg";

import "components/HearFromCustomer/hearFromCustomer.scss";

const customerWords = [
  {
    mainHeading: "Xexxon Panjamapirom",
    heading: "Amazon Seller - USA",
    star: 5,
    para: "Great company!! I am US Company and CrediLinq makes the life of SME much easier. I got approved for in just 7 days. Highly recommended!!!."
  },
  {
    mainHeading: "Muhammad Ibrahim",
    heading: "Amazon Seller – UK",
    star: 5,
    para: "Been searching a lot of financing options but when I came across CrediLinq’s Amazon seller financing, I realized this is the one product which fits every amazon seller needs and focuses on main problem of Woking capital gap. The process of approval is fast and I have already completed 2 successful withdrawals."
  },
  {
    mainHeading: "Carlos",
    heading: "Amazon seller – USA",
    star: 5,
    para: "I got an offer within 9 hours without submitting bank statements or similar documents. I like when you know how much you are going to get before submitting documents. The process was simple, authorize your amazon store and see if you are eligible or not and take a call if you wish to proceed."
  }
];
const amazonLLSWords = [
  {
    mainHeading: "Cameron Williamson",
    heading: "Amazon Seller - USA",
    star: 4,
    para: "As an Amazon seller, managing cash flow and ensuring timely restocks are critical to the success of my business."
  },
  {
    mainHeading: "Floyd Miles",
    heading: "Amazon Seller – UK",
    star: 4,
    para: "I highly recommend CrediLinq to any Amazon seller looking to grow their business and achieve financial stability."
  },
  {
    mainHeading: "Jerome Bell",
    heading: "Amazon Seller, London",
    star: 4,
    para: "I highly recommend CrediLinq to any Amazon seller looking to grow their business and achieve financial stability."
  },
  {
    mainHeading: "Cody Fisher",
    heading: "Amazon Seller, London",
    star: 4,
    para: "As an Amazon seller, managing cash flow and ensuring timely restocks are critical to the success of my business."
  },
  {
    mainHeading: "Jane Cooper",
    heading: "Amazon Seller, London",
    star: 4,
    para: "The application process was straightforward, and I received approval faster than I expected."
  },
  {
    mainHeading: "Jenny Wilson",
    heading: "Amazon Seller, London",
    star: 4,
    para: "The application process was straightforward, and I received approval faster than I expected."
  }
];
const tiktokCustomerWords = [
  {
    mainHeading: "Putra Prabowo",
    heading: "TikTok Shop Seller, Indonesia",
    star: 5,
    para: "Credilinq’s TikTok seller financing has been instrumental in growing my business. It streamlined my cash flow and ensured I never missed a restock, boosting my sales and efficiency. Highly recommended for anyone serious about expanding their TikTok shop"
  },
  {
    mainHeading: "Thomas Scott",
    heading: "TikTok Shop Seller, USA",
    star: 5,
    para: "Credilinq’s TikTok seller financing has been a game-changer for my business. It’s simplified my financial management and accelerated my growth, leading to increased sales and smoother operations."
  },
  {
    mainHeading: "Rizal Adi",
    heading: "TikTok Shop Seller, Indonesia",
    star: 5,
    para: "Credilinq’s TikTok seller financing has greatly enhanced my business operations. It has made financial management easier and fueled my growth, leading to better sales and efficiency"
  }
];

const HearFromCustomer = () => {
  const isTic = location.pathname === "/tiktok-shop-seller-financing";
  const isAmazonLLS = location.pathname === "/cl-lls/seller-financing";

  return (
    <section className="hear">
      <div className="container hearContainer">
        <div className="headingBox">
          <h2>
            Hear From <span>Our Customers</span>
          </h2>
        </div>
        <div className="customersBox">
          {(isTic
            ? tiktokCustomerWords
            : isAmazonLLS
            ? amazonLLSWords
            : customerWords
          ).map((itm, ind) => (
            <>
              <div key={ind} className="hearCard">
                <div className="hearCardTop">
                  <h2>{itm.mainHeading}</h2>
                  <h3>{itm.heading}</h3>
                  <div className="starsBox">
                    {Array(itm?.star)
                      .fill(null)
                      .map((_, ind) => (
                        <img key={ind} src={goldenStar} alt="" />
                      ))}
                    {Array(5 - itm?.star)
                      .fill(null)
                      .map((_, ind) => (
                        <img key={ind} src={silverStar} alt="" />
                      ))}
                  </div>
                </div>
                <p>{itm.para}</p>
              </div>
            </>
          ))}
        </div>
        {/* <p className="viewMore">
          View More <img src={downArrow} alt="" />
        </p> */}
      </div>
    </section>
  );
};

export default HearFromCustomer;
