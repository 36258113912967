import iso from "assets/images/home/iso.svg";
import sfa from "assets/images/home/sfa.svg";
import line from "assets/images/home/line.svg";
import { Link, useNavigate } from "react-router-dom";

const Certification = () => {
  const navigate = useNavigate();
  return (
    <section className="certification">
      <div className="container">
        <span className=" commonHeading customerHeading commonGradient customerGradient">
          Certifications
        </span>
        <p>
          We pride ourselves on being your trusted ISO & SFA certified financial
          partner with our commitment to excellence and adherence to
          international standards.
        </p>
        <div className="isoContainer">
          <Link to="/certificates">
            <div className="isoContent">
              <img className="iso" src={iso} alt="isoImage" />
              <img src={line} alt="lineImage" />
              <h1 className="commonHeading customerHeading commonGradient customerGradient">
                International Organization for Standardization
              </h1>
            </div>
          </Link>

          <div
            className="isoContent"
            onClick={() => {
              navigate("/certificates");
            }}
          >
            <img className="sfa" src={sfa} alt="sfaImage" />
            <img src={line} alt="lineImage" />

            <h1 className="commonHeading customerHeading commonGradient customerGradient">
              Singapore Fintech Association
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
