import { useEffect, useRef, useState } from "react";

import ReactGA from "react-ga";

import useScreenMobile from "hooks/useScreenMobile";

import Banner from "components/Banner";
import HowWorks from "components/HowWorks";
import ReasonStatistics from "components/ReasonStatistics";
import SmeHealthJobs from "components/SmeHealthJobs";
import HearFromCustomer from "components/HearFromCustomer/HearFromCustomer";

import { amazonSellerAccordion } from "constant/accordion";
import { sellerCardData } from "constant/reasonData";
import { howWorksDataAmazon } from "constant/howWorksData";

// import HowItWorks from "components/HowItWorks";
// import CredilinqBusiness from "components/credilinqBusiness";
// import Growth from "components/Growth";
// import WhyChoose from "containers/AmazonSeller/WhyChoose";

// import AmazonStore from "containers/AmazonSeller/AmazonStore";
// TODO  import EverythingToKnow from "containers/AmazonSeller/EverythingToKnow";

// import { howItWorksData } from "constant/howItWorksData";

import bannerImage from "assets/images/amazonSeller/amazonSellerBanner.png";
import bannerImagePhn from "assets/images/amazonSeller/amazonSellerBannerPhn.png";

import featuresSvg from "assets/images/amazonSeller/features.svg";
import blackFeaturesSvg from "assets/images/amazonSeller/blackfeatureIcon.svg";
import howItWorksSvg from "assets/images/amazonSeller/howItWorks.svg";
import whiteHowItWorksSvg from "assets/images/amazonSeller/whiteHowItsWorkIcon.svg";
import faqSvg from "assets/images/amazonSeller/faq.svg";
import whiteFaqSvg from "assets/images/amazonSeller/whiteFAQIcon.svg";
import testimonialsSvg from "assets/images/amazonSeller/testimonials.svg";
import whiteTestimonialsSvg from "assets/images/amazonSeller/whiteTestimonial.svg";

import downloadPaper from "assets/images/header/downloadPaper.png";
import successImg from "assets/images/footer/success.svg";

import "containers/AmazonSeller/amazonSeller.scss";
import WhitePaperForm from "components/WhitePaperForm/index";

const navButtons = [
  {
    name: "Testimonials",
    icon: testimonialsSvg,
    whiteIcon: whiteTestimonialsSvg
  },
  { name: " Features", icon: blackFeaturesSvg, whiteIcon: featuresSvg },
  { name: "How it Works", icon: howItWorksSvg, whiteIcon: whiteHowItWorksSvg },
  { name: " FAQ", icon: faqSvg, whiteIcon: whiteFaqSvg }
];

const AmazonSeller = () => {
  const TRACKING_ID = "UA-198986171-1";

  const [showEbook, setShowEbook] = useState(true);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [sticky, setSticky] = useState(false);

  const sectionTabsRefs = useRef(null);
  const sectionsRef = useRef([]);

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // const sectionTabsOffset =
      //   sectionTabsRefs.current?.offsetTop +
      //   sectionTabsRefs.current?.offsetHeight;

      if (scrollTop > 722) {
        setSticky(true);
      }
      if (scrollTop <= 622) {
        setSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionsRef.current.findIndex(
              (section) => section.id === entry.target.id
            );
            setActiveTab(index);
          }
        });
      },
      { threshold: [0.5, 1] }
    );

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (sectionsRef.current[index]) {
      const navHeight = sectionTabsRefs.current?.offsetHeight;
      sectionsRef.current[index].style.scrollMarginTop = `${navHeight}px`;

      sectionsRef.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  };

  const handleCLick = () => {
    setShowEbook(false);
  };

  const isTab = useScreenMobile({ size: 769 });
  const isMobile = useScreenMobile({ size: 569 });
  const imageSource = isMobile ? bannerImagePhn : bannerImage;

  // const growthData = {
  //   title: "You Focus on your Business. We Will Handle Your Financing",
  //   subText:
  //     "Scale ahead of your peers by leveraging CrediLinq.Ai’s Amazon seller financing today.",
  //   buttonText: "Apply Now",
  //   link: "https://sellerfinancing.credilinq.ai/?hsCtaTracking=3d876b1b-fe85-4b0f-9d28-120a79c7e906%7C00af0297-9ae8-42ac-b832-87e634483f8c"
  // };

  // const bannerData = {
  //   title: "",
  //   gradientText: "Working Capital For Amazon Sellers",
  //   paraGraph:
  //     "Qualified sellers can get a credit line up to $2M. Fund your marketing, inventory, and purchase orders.Pay only when you use the funds",
  //   specialText: "FAST  |  FLEXIBLE  |  AFFORDABLE",
  //   // clickingText: "* By clicking this, you will be redirected to Amazon Seller Central.",
  //   clickingText: (
  //     <span>
  //       <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span>
  //       By clicking this, you will be redirected to Amazon Seller Central.
  //     </span>
  //   ),
  //   bannerImg: imageSource,
  //   button: "Apply Now",
  //   link: "https://sellercentral.amazon.com/selling-partner-appstore/dp/amzn1.sp.solution.8929c9a5-7836-49fc-9a92-53ea94c71151"
  // };

  const bannerData = {
    title: "",
    gradientText: "Working Capital For Amazon Sellers",
    paraGraph: (
      <span>
        Qualified sellers can get a credit line up to $2M. Fund your marketing,
        inventory, and purchase orders.
        <span className="amazonContentText">
          {" "}
          Pay only when you use the funds
        </span>
      </span>
    ),
    specialText: "FAST | FLEXIBLE | AFFORDABLE",
    clickingText: (
      <span>
        <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span> By
        clicking this, you will be redirected to Amazon Seller Central.
      </span>
    ),
    bannerImg: imageSource,
    button: "Apply Now",
    link: "https://sellercentral.amazon.com/selling-partner-appstore/dp/amzn1.sp.solution.8929c9a5-7836-49fc-9a92-53ea94c71151"
  };

  const payLaterDirectTitle = {
    title: "Frequently Asked ",
    subTitle: "Questions"
  };

  const sections = [
    {
      component: HearFromCustomer
    },
    {
      component: ReasonStatistics,
      props: {
        reasonData: sellerCardData,
        amazonSellerCardHeading: "amazonSellerCardHeading",
        amazonSellerTitleFlex: "amazonSellerTitleFlex",
        amazonSellerTitleHeading: "amazonSellerTitleHeading"
      }
    },
    {
      component: HowWorks,
      props: {
        howWorksData: howWorksDataAmazon
      }
    },
    {
      component: SmeHealthJobs,
      props: {
        accordionData: amazonSellerAccordion,
        accordionTitle: payLaterDirectTitle,
        amazonSellerBackground: "amazonSellerBackground",
        amazonSellerFaqBottomGap: "amazonSellerFaqBottomGap"
      }
    }
  ];

  return (
    <div className="amazonSeller">
      <Banner
        bannerData={bannerData}
        amazonSellerBannerData={"amazonSellerBannerData"}
        amazonSellerBannerParaData={"amazonSellerBannerParaData"}
        amazonSellerBannerImage={"amazonSellerBannerImage"}
        amazonSellerSpecialText={"amazonSellerSpecialText"}
        amazonSellerCardHeading={"amazonSellerCardHeading"}
        amazonSellerClickingText={"amazonSellerClickingText"}
      />
      <div className="sectionTabsBox">
        {(!isMobile || !isTab) && (
          <div
            className={`sectionsNav ${sticky ? "sectionNav sticky" : ""}`}
            ref={sectionTabsRefs}
          >
            <div className="buttonsBox">
              {navButtons.map((item, index) => (
                <button
                  className={`button ${activeTab === index ? "active" : ""} `}
                  key={index}
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    src={activeTab === index ? item.whiteIcon : item.icon}
                    alt=""
                  />
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        )}

        {sections.map(({ component: Component, props }, index) => (
          <section
            // style={{
            //   minHeight: "100vh",
            //   scrollMarginTop: "70px"
            // }}
            key={index}
            id={`section${index}`}
            ref={(el) => (sectionsRef.current[index] = el)}
          >
            <Component {...props} />
          </section>
        ))}

        {/* <div id="one">
          <ReasonStatistics
            reasonData={sellerCardData}
            amazonSellerCardHeading={"amazonSellerCardHeading"}
            amazonSellerTitleFlex={"amazonSellerTitleFlex"}
            amazonSellerTitleHeading={"amazonSellerTitleHeading"}
          />
        </div> */}
        {/* <div id="two"> */}
        {/* <HowWorks howWorksData={howWorksDataAmazon} /> */}
        {/* <HowItWorks howItWorksData={howItWorksData} /> */}
        {/* <Growth growthData={growthData} childClass={"growthStatisticsText"} /> */}
        {/* <AmazonStore /> */}
        {/* TODO <EverythingToKnow /> */}
        {/* <WhyChoose /> */}
        {/* </div> */}

        {/* <div className="three">
          <SmeHealthJobs
            accordionData={amazonSellerAccordion}
            accordionTitle={payLaterDirectTitle}
            amazonSellerBackground={"amazonSellerBackground"}
            amazonSellerFaqBottomGap={"amazonSellerFaqBottomGap"}
          />
        </div> */}

        {/* <div id="four">
          <HearFromCustomer />
        </div> */}
        {/* <CredilinqBusiness /> */}
      </div>
      {showEbook && (
        <div className="eBookwrapper">
          <WhitePaperForm
            className="whitePapper"
            setShowEbook={setShowEbook}
            showEbook={showEbook}
            setShowEmailSuccess={setShowEmailSuccess}
          />
        </div>
      )}
      {!showEbook && (
        <div className="fixed" onClick={() => setShowEbook(true)}>
          <img src={downloadPaper} alt="img" />
          <span>
            Download <p>Free - eBook</p>
          </span>
        </div>
      )}
      {showEmailSuccess && (
        <div className="downloadFormModal" onClick={handleCLick}>
          <div className="downloadFormPopUp">
            <img src={successImg} alt="successImg" />
            <h5 className="downloadFormPopUpText">Email Sent Successfully.</h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmazonSeller;
