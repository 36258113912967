import HowShare from "assets/images/amazonSeller/howShare.png";
import HowGetApproval from "assets/images/amazonSeller/howGetApproval.png";
import HowFinish from "assets/images/amazonSeller/howFinish.png";
import HowAccess from "assets/images/amazonSeller/howAccess.png";

export const howWorksDataAmazon = [
  {
    img: HowShare,
    title: "Share Data",
    content:
      "Click “Apply now” and you will be redirected to Amazon Seller Central. Click 'Authorize Now' to share your store data. If you’re selling in multiple marketplaces, authorize each separately from the respective Selling Partner Appstore.",
    number: 0
  },
  // {
  //   img: HowApproval,
  //   title: "Approval",
  //   content:
  //     "Receive up to USD  5,000,000 in growth capital to supercharge your business.",
  //   para: "Single Service Fee between 1% to 3% per month with no other charges, for the loan tenure.",
  //   number: 1
  // },
  {
    img: HowGetApproval,
    title: "Get Approval-in-principle",
    content:
      "Get a notification via email about indicative credit line and service fee within 24 hours.",
    number: 1
  },
  {
    img: HowFinish,
    title: "Finish Credit Documentation",
    content: "Complete KYB, KYC and digitally sign credit agreement.",
    number: 2
  },
  {
    img: HowAccess,
    title: "Access Funds",
    content:
      "Access the CrediLinq Portal to withdraw funds and choose the duration to repay.",
    number: 3
  }
];

export const howWorksDataTikTok = [
  {
    img: HowShare,
    title: "Pre-work:  Readiness",
    content: (
      <span>
        Download the last 12 months settlement report (
        <a
          href="https://seller-us.tiktok.com/university/essay?knowledge_id=2336057241700098&default_language=en&identity=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          see here
        </a>
        ) , image of the shop health score (
        <a
          href="https://i.ibb.co/vsDddVr/Shop-health-score.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          example
        </a>
        ), and shop experience score (
        <a
          href="https://i.ibb.co/ZHshxbx/Shop-experience-score.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          example
        </a>
        ). Now you&apos;re ready to apply.
      </span>
    ),
    number: 0
  },
  {
    img: HowGetApproval,
    title: "Onboarding",
    content:
      "Click on 'Apply Now'.  Indicate limit requested, basic details and upload the TikTok seller information.",
    number: 1
  },
  {
    img: HowFinish,
    title: "Approval & Loan Documentation",
    content: "Provide KYC documents and sign loan documents.",
    number: 2
  },
  {
    img: HowAccess,
    title: "Access Capital for Growth",
    content:
      "Choose loan amount and repayment date, view fees and submit request.",
    number: 3
  }
];
