import "containers/IsmsPolicy/ismsPolicy.scss";

const IsmsPolicy = () => {
  return (
    <section className="ismsPolicy">
      <div className="container ismsPolicyContainer">
        <div className="commonHeading ismsPolicyCommonHeading">
          <h1>ISMS Policy Statement</h1>
        </div>
        <div className="ismsPolicyWrapper">
          Confidentiality, Integrity and Availability of information in
          Information Security Management are integral parts of its management
          function and view these as their primary responsibility and
          fundamental to best business practice. Information security policy is
          aligned to the requirements of ISO/IEC 27001; Credilinq is committed
          to
        </div>
        <div className="ismsPolicyWrapper">
          Confidentiality, Integrity and Availability of information in
          Information Security Management are integral parts of its management
          function and view these as their primary responsibility and
          fundamental to best business practice. Information security policy is
          aligned to the requirements of ISO/IEC 27001; Credilinq is committed
          to
        </div>
        <ul className="ismsPolicyWrapper">
          <li>
            Comply to all applicable laws and regulations and contractual
            obligations
          </li>
          <li>
            Implement Information Security Objectives that take into account
            information security requirements following the results of
            applicable risk assessments
          </li>
          <li>
            Communicate these Objectives and performance to all interested
            parties
          </li>
          <li>
            Adopt an Information Security Management System comprising manual
            and procedures which provide direction and guidance on information
            security matters relating to employees, customers, suppliers and
            other interested parties who come into contact with its work
          </li>
          <li>
            Work closely with Customers, Business partners and Suppliers in
            seeking to establish appropriate information security standards
          </li>
          <li>
            Adopt a forward-thinking approach on future business decisions,
            including the continual review of risk evaluation criteria, which
            may impact on Information Security
          </li>
          <li>
            Ensure management resources to better meet information security
            requirements
          </li>
          <li>
            Instruct all members of staff in the needs and responsibilities of
            Information Security Management
          </li>
          <li>Constantly strive to meet its customer&apos;s expectations</li>
          <li>
            Implement continual improvement initiatives, including risk
            assessment and risk treatment strategies
          </li>
        </ul>
        <div className="ismsPolicyWrapper">
          Responsibility for upholding this policy is company-wide under the
          authority of the Managing Director who encourages the personal
          commitment of all staff to address information security as part of
          their skills.
        </div>
        <div className="ismsPolicyWrapper">
          The policy has been approved by the Directors and is reviewed annually
          or sooner should a significant change occur in order to ensure its
          continuing suitability, adequacy and effectiveness.
        </div>
      </div>
    </section>
  );
};

export default IsmsPolicy;
