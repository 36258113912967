import React, { useEffect, useState } from "react";
import useScreenMobile from "hooks/useScreenMobile";
import certificateIcon from "assets/images/header/certificates.svg";
import registrationcertificate from "assets/images/certificates/registrationCertification.png";
import registrationMobile from "assets/images/certificates/registrationMobile.png";
import registrationModal from "assets/images/certificates/registrationModal.png";
import fintechWhiteCertificate from "assets/images/certificates/fintechWhiteCertificate.png";
import fintechMobile from "assets/images/certificates/fintechMobile.png";
import searchCertificate from "assets/images/certificates/searchCertificate.svg";
import fintechModal from "assets/images/certificates/fintechModal.png";
import crossRound from "assets/images/certificates/crossRound.svg";
import "containers/Certificates/certificates.scss";
const Certificates = () => {
  const isMobile = useScreenMobile({ size: 568 });
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(null);
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modal]);
  return (
    <section className="certificates">
      {modal && (
        <div className="certificatesModal">
          <div className="certificatesModalContent">
            <div className="certificate">
              {active === 0 && <img src={registrationModal} alt="" />}
              {active === 1 && <img src={fintechModal} alt="" />}
              <img
                className="crossRound"
                src={crossRound}
                alt="crossRound"
                onClick={() => {
                  setModal(false);
                  setActive(null);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="container certificatesContainer">
        <div className="certificateDetails">
          <div className="certificateDetailsTop">
            <div className="iconBox">
              <img src={certificateIcon} alt="" />
            </div>
            <h1>ISO Certificate</h1>
            <div className="paraBox">
              <p>
                ISO 27001 is the world&apos;s best-known standard for
                Information Security Management Systems (ISMS). This standard
                provides a systematic approach to manage sensitive company
                information and to keep it secure
              </p>
              <p>
                Obtaining ISO 27001 certification demonstrates CrediLinq&apos;s
                commitment to safeguard its critical data assets. Conformity
                with this standard means that CrediLinq has put in place a
                system to manage risks related to manage risk and security of
                the data owned or handled by the company, and that this system
                respects all the best practices and principles of this
                international standard
              </p>
            </div>
          </div>
          <div className="certificateDetailsBottom">
            <div className="certificateBox">
              <div className="modal">
                <button
                  onClick={() => {
                    setModal(true);
                    setActive(0);
                  }}
                >
                  <img src={searchCertificate} alt="" />
                  Preview Certificate
                </button>
              </div>
              {isMobile ? (
                <img src={registrationMobile} alt="" />
              ) : (
                <img
                  className="whiteCertificate"
                  src={registrationcertificate}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
        <div className="certificateDetails" id="sfaCertificate">
          <div className="certificateDetailsTop">
            <div className="iconBox">
              <img src={certificateIcon} alt="" />
            </div>
            <h1>SFA Certificate</h1>
            <div className="paraBox">
              <p>
                The Singapore Fintech Association (SFA) certification is
                recognized by the Monetary Authority of Singapore (MAS) and is a
                testament of CrediLinq&apos;s significant contributions to the
                fintech sector and its commitment to quality and innovation.
              </p>
              <p>
                It represents our dedication to excellence and adherence to the
                highest standards of security, compliance, and technological
                innovation
              </p>
            </div>
          </div>
          <div className="certificateDetailsBottom">
            <div className="certificateBox">
              <div className="modal">
                <button
                  onClick={() => {
                    setModal(true);
                    setActive(1);
                  }}
                >
                  <img src={searchCertificate} alt="" />
                  Preview Certificate
                </button>
              </div>
              {isMobile ? (
                <img src={fintechMobile} alt="" />
              ) : (
                <img
                  className="whiteCertificate"
                  src={fintechWhiteCertificate}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Certificates;
