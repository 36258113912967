import { useRef, useState } from "react"; // Import useState hook
import { Link } from "react-router-dom";
import { customerStoriesData } from "constant/customerStoriesData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// import customerPhoto1 from "assets/images/home/testimonialImage1.png";
// import customerPhoto2 from "assets/images/home/testimonialImage2.png";
import "components/CustomerStories/customerStories.scss";

import "@splidejs/react-splide/css";
import useScreenMobile from "hooks/useScreenMobile";
import nextIcon from "assets/images/founder/nextIcon.svg";
import prevIcon from "assets/images/founder/previousIcon.svg";

const CustomerStories = ({
  renderContent,
  renderData,
  extraClass,
  secondClass,
  customerClass
}) => {
  const isMobile = useScreenMobile({ size: 992 });

  const splideRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const splideOptions = {
    fixedWidth: !isMobile ? "auto" : "100%",
    start: 0,
    perPage: !isMobile ? 4 : 1,
    gap: 24,
    rewind: false,
    pagination: false,
    arrows: false,
    rewindByDrag: true,
    focus: "left",
    classes: {
      arrows: "splide__arrows your-class-arrows",
      arrow: "splide__arrow splideArrow",
      prev: "splide__arrow--prev previousArrow",
      next: "splide__arrow--next nextArrow",
      pagination: "splide__pagination your-class-pagination", // container
      page: "splide__pagination__page your-class-page" // each button
    },
    breakpoints: {
      1440: {
        perPage: 4
      },
      1190: {
        perPage: 3,
        gap: "18px"
      },
      992: {
        perPage: 2,
        gap: "18px"
      },
      800: {
        perPage: 2,
        gap: "18px"
      },
      450: {
        perPage: 1
      }
    }
  };

  const goToNextSlide = () => {
    if (splideRef.current) {
      splideRef.current.go("+1");
    }
  };

  const goToPrevSlide = () => {
    if (splideRef.current) {
      splideRef.current.go("-1");
    }
  };

  const handleMove = (_, newIndex) => {
    setCurrentPage(newIndex + 1);
  };
  return (
    <section className={"commonBackground customer " + customerClass}>
      <div className="container">
        <h1>{renderContent}</h1>
        <Splide
          aria-label="My Favorite Images"
          options={splideOptions}
          onFirstMount={() => setCurrentPage(1)}
          onMove={handleMove}
          ref={splideRef}
        >
          {customerStoriesData.map((data, index) => (
            <SplideSlide key={index}>
              <div className="customerContainerSection">
                <div className="customerImage">
                  <img src={data.image} alt="customerPhoto" />
                </div>
                <div className="customerSection">
                  <label className="customerSectionIndustry">
                    {data.industry}
                  </label>
                  <p className="customerContent">&quot;{data.content}&quot;</p>
                  {/* <div>
                    <span>{data.profileName}</span>
                    <p className={secondClass}>{data.position}</p>
                  </div> */}
                  {index === 0 && renderData && (
                    <Link to={"/case-study"} target="_blank">
                      <div>{renderData}</div>
                    </Link>
                  )}
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className="counter counterCustomerStories">
        <span>
          <img
            src={prevIcon}
            alt="prevImg"
            className={`counterImg ${currentPage === 1 ? "disabled" : ""}`}
            onClick={goToPrevSlide}
          />
          <span
            className={`counterNumber ${
              currentPage === 1 || currentPage === 2 ? "active" : ""
            }`}
          >
            {Math.ceil(currentPage)} / {customerStoriesData?.length}
          </span>
          <img
            src={nextIcon}
            alt="nextImg"
            className={`counterImg ${
              currentPage === customerStoriesData?.length ? "disabled" : ""
            }`}
            onClick={goToNextSlide}
          />
        </span>
      </div>

      <div className="counterResponsive counterResponsiveCustomer ">
        <span>
          <img
            src={prevIcon}
            alt="prevImg"
            className={`counterImg ${currentPage === 1 ? "disabled" : ""}`}
            onClick={goToPrevSlide}
          />
          {/* {Math.ceil(currentPage / 3)} / {articleBlog?.length / 3} */}
          {Math.ceil(currentPage)} / {customerStoriesData?.length}
          <img
            src={nextIcon}
            alt="nextImg"
            className={`counterImg ${
              currentPage === customerStoriesData?.length ? "disabled" : ""
            }`}
            onClick={goToNextSlide}
            // style={{
            //   visibility:
            //     currentPage === articleBlog.length ? "hidden" : "visible"
            // }}
          />
        </span>
      </div>
    </section>
  );
};

export default CustomerStories;
