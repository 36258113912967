export const linkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M22.0003 0.052002C22.7076 0.052002 23.3858 0.332953 23.8859 0.83305C24.386 1.33315 24.667 2.01142 24.667 2.71867V21.3853C24.667 22.0926 24.386 22.7709 23.8859 23.271C23.3858 23.7711 22.7076 24.052 22.0003 24.052H3.33366C2.62641 24.052 1.94814 23.7711 1.44804 23.271C0.947944 22.7709 0.666992 22.0926 0.666992 21.3853V2.71867C0.666992 2.01142 0.947944 1.33315 1.44804 0.83305C1.94814 0.332953 2.62641 0.052002 3.33366 0.052002H22.0003ZM21.3337 20.7187V13.652C21.3337 12.4992 20.8757 11.3936 20.0606 10.5784C19.2454 9.76329 18.1398 9.30534 16.987 9.30534C15.8537 9.30534 14.5337 9.99867 13.8937 11.0387V9.55867H10.1737V20.7187H13.8937V14.1453C13.8937 13.1187 14.7203 12.2787 15.747 12.2787C16.2421 12.2787 16.7169 12.4753 17.0669 12.8254C17.417 13.1755 17.6137 13.6503 17.6137 14.1453V20.7187H21.3337ZM5.84033 7.46534C6.43441 7.46534 7.00416 7.22934 7.42425 6.80926C7.84433 6.38917 8.08033 5.81942 8.08033 5.22534C8.08033 3.98534 7.08033 2.972 5.84033 2.972C5.2427 2.972 4.66956 3.20941 4.24698 3.63199C3.8244 4.05457 3.58699 4.62771 3.58699 5.22534C3.58699 6.46534 4.60033 7.46534 5.84033 7.46534ZM7.69366 20.7187V9.55867H4.00033V20.7187H7.69366Z"
      fill="#2D64BC"
    />
  </svg>
);
