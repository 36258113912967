import { useEffect, useRef, useState } from "react";
import alertImg from "assets/images/lls/alert.svg";
import ReactGA from "react-ga";

import useScreenMobile from "hooks/useScreenMobile";

import Banner from "components/Banner";
import ReasonStatistics from "components/ReasonStatistics";
import { LLSsellerCardData } from "constant/reasonData";

// import HowItWorks from "components/HowItWorks";
// import CredilinqBusiness from "components/credilinqBusiness";
// import Growth from "components/Growth";
// import WhyChoose from "containers/AmazonSeller/WhyChoose";

// import AmazonStore from "containers/AmazonSeller/AmazonStore";
// TODO  import EverythingToKnow from "containers/AmazonSeller/EverythingToKnow";

// import { howItWorksData } from "constant/howItWorksData";

import bannerImage from "assets/images/amazonSeller/amazonSellerBanner.png";
import bannerImagePhn from "assets/images/amazonSeller/amazonSellerBannerPhn.png";

import downloadPaper from "assets/images/header/downloadPaper.png";
import successImg from "assets/images/footer/success.svg";

import "containers/AmazonSeller/amazonSeller.scss";

const AmazonSeller = () => {
  const TRACKING_ID = "UA-198986171-1";

  const [showEbook, setShowEbook] = useState(true);
  const [showEmailSuccess] = useState(false);

  const sectionsRef = useRef([]);

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleCLick = () => {
    setShowEbook(false);
  };

  const isMobile = useScreenMobile({ size: 569 });
  const imageSource = isMobile ? bannerImagePhn : bannerImage;

  const bannerData = {
    title: "",
    gradientText: "Working Capital For Amazon Sellers",
    paraGraph: (
      <span>
        Qualified sellers can get a credit line up to $2M. Fund your marketing,
        inventory, and purchase orders.
        <span className="amazonContentText">
          {" "}
          Pay only when you use the funds
        </span>
      </span>
    ),
    specialText: "FAST | FLEXIBLE | AFFORDABLE",
    clickingText: (
      <span>
        <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span> By
        clicking this, you will be redirected to Amazon Seller Central.
      </span>
    ),
    bannerImg: imageSource,
    button: "Apply Now",
    link: "https://sellercentral.amazon.com/selling-partner-appstore/dp/amzn1.sp.solution.8929c9a5-7836-49fc-9a92-53ea94c71151"
  };

  const sections = [
    {
      component: ReasonStatistics,
      props: {
        reasonData: LLSsellerCardData,
        amazonSellerCardHeading: "amazonSellerCardHeading",
        amazonSellerTitleFlex: "amazonSellerTitleFlex",
        amazonSellerTitleHeading: "amazonSellerTitleHeading"
      }
    }
  ];

  return (
    <div className="amazonSeller">
      <Banner
        bannerData={bannerData}
        amazonSellerBannerData={"amazonSellerBannerData"}
        amazonSellerBannerParaData={"amazonSellerBannerParaData"}
        amazonSellerBannerImage={"amazonSellerBannerImage"}
        amazonSellerSpecialText={"amazonSellerSpecialText"}
        amazonSellerCardHeading={"amazonSellerCardHeading"}
        amazonSellerClickingText={"amazonSellerClickingText"}
      />
      <div className="sectionTabsBox">
        {sections.map(({ component: Component, props }, index) => (
          <section
            // style={{
            //   minHeight: "100vh",
            //   scrollMarginTop: "70px"
            // }}
            key={index}
            id={`section${index}`}
            ref={(el) => (sectionsRef.current[index] = el)}
          >
            <Component {...props} />
          </section>
        ))}
      </div>
      <div className="container">
        <div className="desclimerSection">
          <img src={alertImg} />
          This material is provided for informational purposes only. It does not
          constitute an offer or a commitment of Linklogis or Credilinq, and is
          not intended to be legally binding.
        </div>
      </div>

      {!showEbook && (
        <div className="fixed" onClick={() => setShowEbook(true)}>
          <img src={downloadPaper} alt="img" />
          <span>
            Download <p>Free - eBook</p>
          </span>
        </div>
      )}
      {showEmailSuccess && (
        <div className="downloadFormModal" onClick={handleCLick}>
          <div className="downloadFormPopUp">
            <img src={successImg} alt="successImg" />
            <h5 className="downloadFormPopUpText">Email Sent Successfully.</h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmazonSeller;
