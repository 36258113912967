import { Link } from "react-router-dom";

import "components/BannerCertificate/bannerCertificate.scss";
import Arrow from "constant/arrow";

import isoBanner from "assets/images/home/isoBanner.svg";
import sfaBanner from "assets/images/home/sfaBanner.png";
import { smoothNavigation } from "utils/scrollBehaviour";
const index = () => {
  return (
    <section>
      <div className="container certificateContainer">
        <div className="isoContainer">
          <div className="isoBanner">
            <img src={isoBanner} alt="" />
          </div>
          <div className="isoContent">
            <h1 className="certificateHeading">
              CrediLinq is <br /> an ISO/IEC 27001:2022 Certified Company
            </h1>
            <p className="certificateContent">
              Our information security management systems are aligned with the
              global standard for data security, cybersecurity, and user privacy
              protection
            </p>
            <Link to="/certificates">
              <button className="commonButton isoButton">
                View Certificate
                <Arrow />
              </button>
            </Link>
          </div>
        </div>
        <div className="isoContainer ">
          <div className="isoContent sfaContent">
            <h1 className="certificateHeading sfaHeading">
              CrediLinq achieves SFA FinTech certification, reinforcing industry
              leadership
            </h1>
            <p className="certificateContent">
              The Fintech Certification from the Singapore Fintech Association
              (SFA) is a coveted recognition that sets us apart as a trusted
              leader in the fintech industry
            </p>

            <Link to={"/certificates/#sfaCertificate"}>
              <div
                onClick={() => {
                  setTimeout(() => {
                    smoothNavigation("sfaCertificate", { sfaCertificate: 45 });
                  }, 300);
                }}
              >
                <button className="commonButton sfaButton">
                  View Certificate
                  <Arrow />
                </button>
              </div>
            </Link>
          </div>
          <div className="sfaBanner">
            <img src={sfaBanner} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
