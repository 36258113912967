import "containers/TermsAndCondition/termsAndCondition.scss";
const TermsAndCondition = () => {
  return (
    <>
      <section className="terms">
        <div className="container">
          <div className="termsHeading">
            <h1>
              ANNEXURE <br />
              AGREEMENT ON STANDARD TERMS AND CONDITIONS
            </h1>
          </div>
          <div className="termsLabel">
            <ol>
              <li>
                <h3>DEFINITIONS</h3>
                <p>
                  In this Agreement, unless the context requires otherwise, the
                  following words and expressions shall have the following
                  meanings:
                </p>
                <p>
                  <span>“Agreement”</span> means this Agreement on standard
                  terms and conditions read together with the Letter of Offer.
                </p>
                <p>
                  <span>“Affiliate”</span> means, in relation to any person, a
                  Subsidiary or a Holding Company of that person or any other
                  Subsidiary of that Holding Company, including the head office
                  and any branch of such person in any jurisdiction;
                </p>
                <p>
                  <span>“AMLA”</span> means the Singapore’s anti-money
                  laundering or countering the financing of terrorism
                  regulations, guidelines, notices and statutes, as may be
                  amended or supplemented from time to time;
                </p>
                <p>
                  <span>“Application”</span> means the Borrower’s application
                  submitted to Lender for the grant of the Facility under the
                  Letter of Offer read together with this Agreement;
                </p>
                <p>
                  <span>“Authorisations”</span> means: (i) an authorisation,
                  consent, approval, resolution, licence, exemption, filing,
                  notarisation, lodgement or registration; or (ii) in relation
                  to anything which will be fully or partly prohibited or
                  restricted by law if a Governmental Agency intervenes or acts
                  in any way within a specified period after lodgement, filing,
                  registration or notification, the expiry of that period
                  without intervention or action;
                </p>
                <p>
                  <span>“Authorised Signatories”</span> means officers or
                  representatives vested, whether explicitly, implicitly,
                  through conduct, or through board resolution as per
                  <span> Schedule 2</span> of this Agreement, with the powers to
                  commit the authorising organisation to a binding agreement,
                  action, decision and instructions;
                </p>
                <p>
                  <span>“Borrower”</span> means the person identified in the
                  Letter of Offer as the Borrower;
                </p>
                <p>
                  <span>“Business Day”</span> means a day (other than a
                  Saturday, Sunday or public holiday in Singapore) when
                  commercial banks are open for banking business in Singapore;
                </p>
                <p>
                  <span>“Debt Recovery Exercise”</span> has the meaning given to
                  it in Clause 12.4 under Clause 12 (Events of Default);
                </p>
                <p>
                  <span>“Disbursement Date”</span> means the date on which the
                  Facility Amount is disbursed to the Borrower;
                </p>
                <p>
                  <span>“Disclosure Document”</span> means the documents
                  submitted to Lender at any point and/or for Application and/or
                  those documents more particularly listed in
                  <span> Schedule 2</span> attached here to;
                </p>
                <p>
                  <span>“Due Date”</span> means the respective repayment date of
                  Facility Amount, Instalment, interest, Processing Fee, Late
                  Payment Interest, Late Penalty Fee, Legal Fee, or any other
                  Fees & Charges which will include the Instalment Payment Date
                  as the case may be, which are specified hereunder;
                </p>
                <p>
                  <span>“Events of Default”</span> means an event of default
                  listed in Clause 12.1 under Clause 12 (Events of Default);
                </p>
                <p>
                  <span>“Facility”</span> means the facility which may from time
                  to time be made available to the Borrower by the Lender up to
                  the Facility Amount subject to the terms and conditions of the
                  Finance Documents;
                </p>
                <p>
                  <span>“Facility Amount”</span> means the maximum principal
                  amount as stated in the Letter of Offer granted to the
                  Borrower as from time to time varied, reduced or cancelled
                  pursuant to the terms hereof;
                </p>
                <p>
                  <span>“Fees & Charges”</span> has the meaning given to it in
                  Clause 6 (Fees & Charges) below;
                </p>
                <p>
                  <span>“Finance Documents”</span> means the Letter of Offer,
                  this Agreement, the Terms and Conditions, the Security
                  Documents, which expression shall include any amendments,
                  variations, supplemental and substitution and any other
                  document designated as such by Lender from time to time;
                </p>
                <p>
                  <span>“Governmental Agency”</span> means any government, or
                  any governmental, regulatory, administrative, public or other
                  authority, agency or department, or any semi-governmental,
                  statutory, judicial or quasi-judicial entity or authority
                  (including any stock exchange or any self-regulatory
                  organisation established under statute);
                </p>
                <p>
                  <span>“Guarantee”</span> has the meaning given to it in Clause
                  15.1 under Clause 15 (Guarantee and Indemnity).
                </p>
                <p>
                  <span>“Guaranteed Obligations”</span> means all debts and
                  monetary liabilities of the Borrower owed to the Lender under
                  or in relation to the Facility under this Agreement or any
                  other Finance Document, and in any capacity irrespective of
                  whether the debts or liabilities: (a) are present or future;
                  (b) are actual, contingent or otherwise; (c) are at any time
                  ascertained or unascertained; (d) are owed or incurred by or
                  on account of the Borrower alone, or severally or jointly with
                  any other person; (e) owed or incurred to or for the account
                  of Lender alone, or severally or jointly with any other
                  person; (f) are owed or incurred as principal, interest, fees,
                  charges, taxes, duties or other imposts, damages (whether for
                  breach of contract or tort or incurred on any other ground),
                  losses, costs or expenses, or on any other account; (g)
                  comprise any combination of the above.
                </p>
                <p>
                  <span> “Guarantor” </span> means each person identified in the
                  Letter of Offer as a Guarantor (including but not limited to
                  individual guarantors and corporate guarantors) who
                  irrevocably and unconditionally indemnify and guarantee to
                  Lender, amongst others, the due and punctual performance and
                  discharge by the Borrower of the Guaranteed Obligations of
                  such Borrower as they fall due pursuant to the Finance
                  Documents and
                  <span> “Guarantor”</span> shall mean all of them;
                </p>
                <p>
                  <span>“Holding Company”</span> has the meaning as ascribed to
                  it under the Companies Act (Cap. 50);
                </p>
                <p>
                  <span>“Indebtedness”</span> means any indebtedness incurred
                  for or in respect of the Facility which include the Facility
                  Amount with accrued interest, Fees & Charges, Late Payment
                  Interest, Late Penalty Fee, costs, expenses and all other
                  amounts accrued or outstanding under this Agreement and any
                  Finance Documents;
                </p>
                <p>
                  <span>“Instalment”</span> means the monthly instalments in
                  respect of the Facility Amount and the amount as determined by
                  Lender to be the amount of interest chargeable for the
                  corresponding period, which amount shall be payable on each of
                  the relevant Instalment Payment Dates;
                </p>
                <p>
                  <span>“Interest Rate”</span> means the percentage rate as
                  specified in the Letter of Offer.
                </p>
                <p>
                  <span>“Instalment Payment Dates”</span> means each of the
                  dates on which an Instalment is due from the Borrower which is
                  on the day immediately following the expiry of the preceding
                  Instalment Period and thereafter on the expiry of every
                  successive interval of each Instalment Period unless otherwise
                  specified;
                </p>
                <p>
                  <span>“Instalment Period”</span> means, each instalment period
                  determined as follows:
                </p>
                <table>
                  <tr>
                    <th>Disbursement Date</th>
                    <th>First Instalment Period</th>
                    <th>Successive Instalment Periods</th>
                  </tr>
                  <tr>
                    <td>1st – 9th of the month</td>
                    <td>
                      Commencing from the disbursement date and ending on the
                      10th of the immediately succeeding month
                    </td>
                    <td>
                      11th of each month till the 10th of the succeeding month.
                    </td>
                  </tr>
                  <tr>
                    <td>10th – 19th of the month</td>
                    <td>
                      Commencing from the disbursement date and ending on the
                      20th of the immediately succeeding month
                    </td>
                    <td>
                      Commencing from the disbursement date and ending on the
                      20th of the immediately succeeding month
                    </td>
                  </tr>
                  <tr>
                    <td>20th – the last day of the month</td>
                    <td>
                      Commencing from the disbursement date and ending on the
                      last day of the following month
                    </td>
                    <td>
                      1st day of each month till the last day of the month.
                    </td>
                  </tr>
                </table>
                <p>
                  <span>“Late Penalty Fee”</span> means the penal fee payable in
                  case of late payment by the Borrower/Guarantors on a Due Date
                  as described in detail in Letter of Offer.
                </p>
                <p>
                  <span>“Late Penalty Interest”</span> has the meaning given to
                  it in Clause 8.1 under Clause 8 (Late Payment Interest) below.
                </p>
                <p>
                  <span>“Legal Fee”</span> means the legal fees payable by the
                  Borrower/Guarantors as described in detail in Letter of Offer.
                </p>
                <p>
                  <span>“Lender”</span> means the person identified in the
                  Letter of Offer as the Lender;
                </p>
                <p>
                  <span>“Lender Account”</span> means the account the of the
                  Lender the details of which is provided in the Letter of
                  Offer.
                </p>
                <p>
                  <span>“Letter of Offer”</span> including any supplemental or
                  amendments thereto, which shall be read together with this
                  Agreement and annexed hereto at the beginning of this
                  Agreement;
                </p>
                <p>
                  <span>“Material Adverse Change”</span> means any or all of the
                  following:
                </p>
                <label>
                  (a) the discovery of a false or misleading statement in the
                  Disclosure Document or Application;
                </label>{" "}
                <br />
                <label>
                  (b) the discovery of a material omission of information
                  required to be included in the Disclosure Document and
                  Application;
                </label>{" "}
                <br />
                <label>
                  (c) there is a material adverse change in the financial
                  condition,operations,business or prospects of the Borrower;
                </label>{" "}
                <br />
                <label>
                  (d) there is a material change in the ability of the Obligor
                  to perform and comply with its obligations under the Agreement
                  or any Finance Documents;
                </label>{" "}
                <br />
                <label>
                  (e) there is a material change in the validity, legality or
                  enforceability or, or the rights or remedies of the Lender
                  under this Agreement or any Finance Documents; or
                </label>
                <br />
                <label>
                  (f) there is a material change in the validity or
                  enforceability of, or the effectiveness or ranking of, any
                  Security granted or purported to be granted to any Security
                  Documents;
                </label>
                <p>
                  <span>“Obligor”</span> means the Borrower or Guarantors or any
                  of the Security Providers and “Obligors” shall mean all of
                  them;
                </p>
                <p>
                  <span>“Officer”</span> in relation to Lender means any
                  director, officer, agent or employee of Lender;
                </p>
                <p>
                  <span>“SGD”</span> means the lawful currency of Singapore;
                </p>
                <p>
                  <span>“Repayment Amount”</span> means the Facility Amount and
                  Interests due and owing to Lender;
                </p>
                <p>
                  <span>“Sanctions”</span> means:
                </p>
                <label>
                  (a) United Nations sanctions imposed pursuant to any United
                  Nations Security Council Resolution;
                </label>{" "}
                <br />
                <label>
                  (b) U.S. sanctions administered by the Office of Foreign
                  Assets Control of the U.S. Department of the Treasury or any
                  other U.S. Government authority or department;
                </label>{" "}
                <br />
                <label>
                  (c) EU restrictive measures implemented pursuant to any EU
                  Council or Commission Regulation or Decision adopted pursuant
                  to a Common Position in furtherance of the EU &apos;s Common
                  Foreign and Security Policy;
                </label>{" "}
                <br />
                <label>
                  (d) UK sanctions adopted by the Terrorist-Asset Freezing etc.
                  Act 2010 or other legislation and statutory instruments
                  enacted pursuant to the United Nations Act 1946 or the
                  European Communities Act 1972 or enacted by or pursuant to
                  other laws;
                </label>{" "}
                <br />
                <label>
                  (e) Singapore sanctions imposed or administered by the
                  Singapore government; and
                </label>{" "}
                <br />
                <label>
                  (f) any other sanctions laws and regulations applicable to any
                  Party or any of its Affiliates.
                </label>
                <p>
                  [<span>“Security”</span> means a mortgage, charge, pledge,
                  lien, security assignment or other security interest securing
                  any obligation of any person or any other agreement or
                  arrangement having a similar effect;
                </p>
                <p>
                  <span>“Security Documents”</span> means all security documents
                  which include but not limited to this Agreement, mortgage
                  documents, charge, debenture, collaterals, the assignment of
                  receivables (if applicable) or any other document entered into
                  by any person which is a guarantee (including a personal
                  and/or corporate guarantee) of, or which creates or evidences
                  or purports to create or evidence any Security over all or any
                  part of its assets in respect of, any of the obligations of
                  any Obligor under the Finance Documents, or any other document
                  entered into in connection with the creation, validity,
                  perfection or priority of any such guarantee or Security which
                  may from time to time be executed to secure the payment by the
                  Borrower or any other Obligor of the Facility;
                </p>
                <p>
                  <span>“Security Providers”</span> means the Guarantors and
                  each party to the Security Documents (other than the Lender);
                </p>
                <p>
                  <span>“Subsidiary”</span> has the meaning as ascribed to it
                  under the Companies Act;
                </p>
                <p>
                  <span>“Tenor”</span> means the time period for repayment of
                  the Facility Amount and Interest and for the Borrower to make
                  or cause to make repayments towards the Facility, being a
                  period stated in the Letter of Offer which is subjected to
                  periodic review;
                </p>
                <p>
                  <span>“Terms and Conditions”</span> means all terms and
                  conditions pertaining to / applicable to the Facility
                  including those contained in this Agreement, any other Finance
                  Document, as mentioned in the Letter of Offer including of any
                  website referred thereto (including the privacy notice,
                  website terms and terms of use).
                </p>
                <p>
                  <span> 1.1</span> The Preamble, Schedules, attachments form
                  part of this Agreement and shall have effect as if set out in
                  full in the body of this Agreement. Any reference to this
                  Agreement includes the preamble and the schedules.
                </p>
                <p>
                  <span> 1.2</span> Words in the singular shall include the
                  plural and vice versa.
                </p>
                <p>
                  <span> 1.3</span> A reference to one gender shall include a
                  reference to other genders.
                </p>
                <p>
                  <span> 1.4</span> A reference to a Party in a document
                  includes that Party’s heirs, executors, administrators,
                  personal representatives, successors and permitted assignees.
                </p>
                <p>
                  <span> 1.5</span> A reference to any statute or statutory
                  provision includes a reference to that statute or statutory
                  provision as from time to time amended, extended or re-enacted
                  and shall include all by-laws, instruments, orders, rules and
                  regulations made thereunder.
                </p>
                <p>
                  <span> 1.6</span> Any reference to “writing” or cognate
                  expressions includes a reference to electronic communication,
                  telex, cable, facsimile transmission, e-mails or comparable
                  means of communications.
                </p>
                <p>
                  <span> 1.7</span> Words denoting an obligation on a Party to
                  do an act, matter or thing includes an obligation to procure
                  that it be done or words placing a party under a restriction
                  include not to permit and infringement of the restriction.
                </p>
                <p>
                  <span> 1.8</span> A reference to a document includes an
                  amendment or supplement to, or replacement or novation of,
                  that document but disregarding any amendment, supplement,
                  replacement or novation made in breach of this Agreement.
                </p>
                <p>
                  <span> 1.9</span> Any reference to “Month”/“month” means a
                  reference to a period starting on one (1) day in a calendar
                  month and ending on the numerically corresponding day in the
                  next succeeding calendar month save that, where any such
                  period would end on a day which is not a Business Day, it
                  shall end on the next succeeding Business Day, unless that day
                  falls in the calendar month succeeding that in which it would
                  otherwise have ended, in which case it shall end on the
                  immediately preceding Business Day. Provided that, if a period
                  starts on the last Business Day in a calendar month or if
                  there is no numerically corresponding day in the month in
                  which that period ends, that period shall end on the last
                  Business Day in that later month (and references to &apos;
                  months &apos; shall be construed accordingly);
                </p>
                <p>
                  <span> 1.10</span> Any determination with respect to the
                  materiality or reasonableness of any matter including of any
                  event, occurrence, circumstance, change, fact, information,
                  document, authorisation, proceeding, act, omission, claims,
                  breach, default or otherwise shall be made by the Lender.
                </p>
              </li>
              <li>
                <h3>GRANT OF THE FACILITY</h3>
                <p>
                  {" "}
                  <span> 2.1</span> Subject to the terms and conditions of this
                  Agreement, Lender has agreed to make available to the Borrower
                  the Facility Amount as stated in the Letter of Offer subject
                  to all the Finance Documents and the Terms and Conditions and
                  subject to review in Clause 3 (Review and Restructuring)
                  below.
                </p>
              </li>
              <li>
                <h3>REVIEW AND RESTRUCTURING</h3>
                <p>
                  {" "}
                  <span> 3.1</span> Notwithstanding any provision herein to the
                  contrary, it is hereby expressly agreed and declared that
                  Lender will have the right at any time in its absolute
                  discretion to review, revise and/or restructure the Facility,
                  any Terms and Conditions or any part thereof (including the
                  revision of the Interest Rate, Penalty Fee) and any variation
                  and/or addition to the Terms and Conditions of the Facility
                  thereafter will take effect on such date as determined by
                  Lender in its absolute discretion. Provided that, the Borrower
                  may, prepay the entire outstanding amounts in connection with
                  the Facility in one lump sum within a period of 7 days from
                  the date of any such review, restructure or revision, if any
                  such review, restructure or revision is not acceptable to the
                  Borrower]
                </p>
              </li>
              <li>
                <h3>CANCELLATION AND MANDATORY PREPAYMENT</h3>
                <p>
                  {" "}
                  <span> 4.1</span> Notwithstanding any provisions in this
                  Agreement to the contrary, it is hereby expressly agreed and
                  declared that Lender will have the right at any time in its
                  absolute discretion, prior to any disbursement or utilisation
                  of the Facility, to cancel / terminate the Facility or any
                  undisbursed part thereof without giving any reasons therefore
                  by giving written notice to the Borrower, and such
                  cancellation/termination will take effect on such date as
                  determined by Lender in its absolute discretion and stated in
                  the written notice without liability to any fees, expenses,
                  damages and costs incurred by the Borrower.
                </p>
                <p>
                  <span> 4.2</span> Notwithstanding any provisions in this
                  Agreement to the contrary, it is hereby expressly agreed and
                  declared that Lender will have the right at any time in its
                  absolute discretion, to call back the entire Facility (and all
                  sums due and payable by the Obligors under the Finance
                  Documents) or any part thereof without giving any reasons
                  therefore by giving written notice to the Borrower. Upon such
                  notice, all sums due and payable by the Obligors under the
                  Finance Documents together with accrued interest at the
                  Interest Rate, or part thereof as may be requested by the
                  Lender, will be immediately due and payable. Thereupon, the
                  Borrower shall within seven (7) days from the date of such
                  written notice repay the whole Facility (and all sums due and
                  payable by the Obligors under the Finance Documents together
                  with accrued interest at the Interest Rate) in one lump sum
                  thereon failing which, Penalty Fee will be applicable and
                  shall continue to accrue until the day of settlement.
                </p>
              </li>
              <li>
                <h3>DISBURSEMENTS OF FACILITY AMOUNT</h3>
                <label>
                  {" "}
                  <span> 5.1</span> Lender will disburse, instruct or cause to
                  transfer the Facility Amount (in full) to the Borrower from
                  the Lender Accounts into the bank account as stated in the
                  Letter of Offer in one lump sum within three (3) Business Days
                  from the date of which all the following condition precedents
                  have been met and/or complied to the satisfaction of Lender
                  with and subject to Clause 5.2 and 5.3 below:
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  {" "}
                  <span> 5.1.1</span> That Lender is in receipt of all the
                  documents required provided in Schedule 2;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>5.1.2</span> No Event of Default has occurred and no
                  event has occurred which would with the expiry of the grace
                  period become an Event of Default. No Event of Default will
                  occur from the Borrower availing the Facility or the any of
                  the Obligors undertaking their respective obligations under
                  the Finance Documents;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>5.1.3</span> That the Borrower has paid Lender all the
                  Fees & Charges;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>5.1.4</span> That Lender is in receipt of an executed
                  direct-debit GIRO authorisation/application form (if
                  required);
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>5.1.5</span> That Lender is in receipt of a resolution
                  of the Board of Directors of the Obligors stating as follows
                  and in any event, in the form as provided in Part A of
                  Schedule 1 for the Borrower and Part B of Schedule 2 for the
                  Guarantor (if required): <br />
                  <label>
                    {" "}
                    <span>(a)</span> Approving the terms of, and the
                    transactions contemplated by, this Agreement and any other
                    Finance Documents and resolving that it executes the same;
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>(b)</span> In the case of the Guarantor, agreeing to
                    guarantee the punctual payment of the indebtedness under
                    this Agreement and any other Finance Documents;
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>(c)</span> In the case of the Security Providers,
                    agreeing to Provide the Security as per this Agreement and
                    any other Security Documents;
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>(d)</span> Authorising a specified person or persons
                    to give instructions and execute any other Finance Documents
                    pursuant to this Agreement; and
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>(e)</span> Confirming and ratifying that the mode of
                    execution of the Finance Documents may be by way of
                    e-signatures in compliance with the Electronic Transactions
                    Act or such other legislations governing e-signatures, if
                    such Finance Document can be executed by way of e-signature;
                  </label>
                  <br />
                </label>
                <label>
                  {" "}
                  <span>5.1.6</span> Where required by the Lender, a certified
                  copy of shareholder resolution of the Obligors (signed by all
                  holders of the issued shares) approving the terms of, and the
                  transactions contemplated by the Finance Documents (to which
                  Borrower is a party);
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>5.1.7</span> A certificate from each Obligor certifying
                  that: <br />
                  <label>
                    <span>(a)</span> The representations and warranties made or
                    given under the Finance Documents are true and accurate in
                    all material respects;
                  </label>
                  <br />
                  <label>
                    {" "}
                    <span>(b)</span> That there is no litigation, arbitration or
                    administrative proceedings which are presently currently or
                    pending, or to the Obligors’ actual knowledge, threatened,
                    against the Obligors, which, if determined adversely in
                    relation to the Obligors, could affect the ability of the
                    Borrower to repay the Facility Amount hereunder in
                    accordance with the terms hereof or which could have a
                    material adverse effect on the financial condition of any of
                    the Obligors or could adversely affect the ability of any of
                    the Obligors to undertake any of the obligations provided in
                    the Finance Documents to which it is a party;
                  </label>
                  <br />
                  <label>
                    {" "}
                    <span>(c)</span> No winding-up petition or application has
                    been presented against any of the Obligors and the Obligor
                    has not entered into liquidation nor has an order of court
                    been made for its compulsory liquidation nor has it entered
                    into any composition or arrangement with its creditors nor
                    have any judicial managers, receivers or managers (including
                    temporary or interim officials) been appointed over the
                    whole of or any part of the assets or undertakings, or
                    caused or become subject to or take any action in
                    furtherance or indicating its consent to, any event with
                    respect to which, under the applicable laws of any
                    jurisdiction, has an analogous effect to any of these
                    events;
                  </label>
                  <br />
                  <label>
                    {" "}
                    <span>(d)</span> No extraordinary circumstances or change of
                    law or other governmental actions or directives (whether
                    having the force of law or not), change in law or other
                    material adverse change of event has occurred which makes it
                    improbable for Lender to disburse the Facility Amount
                    pursuant to the terms of this Agreement or prejudices any of
                    the Obligor’s ability to perform their respective
                    obligations under the Finance Documents;
                  </label>
                  <br />
                  <label>
                    <span>(e)</span> No Event of Default is continuing or would
                    result from the proposed Disbursement Date;
                  </label>
                  <br />
                  <label>
                    <span>(f)</span> There has been no Material Adverse Change
                    since the date of the Application;
                  </label>
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>5.1.8</span> All Finance Documents and other documents
                  and instruments which are deemed necessary or expedient by
                  Lender having been duly executed and stamped (where
                  applicable) and the originals or copies (whether electronic or
                  otherwise) thereof have been deposited with Lender and are in
                  form and substance satisfactory to Lender;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span> 5.1.9</span> That all Security under the Security
                  Document (if applicable) has been entered into and perfected;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span> 5.1.10</span> Any documentation or other evidence which
                  is reasonably requested by Lender for the purpose of any
                  &apos; know your customer&apos; requirements;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span> 5.1.11</span> Where required by Lender, any legal
                  opinion(s) from legal counsel concerning matters of relevant
                  law; and
                </label>
                <br />
                <label>
                  {" "}
                  <span>5.1.12</span> Any other pre-disbursement, or special
                  conditions as stated in the Letter of Offer has been complied
                  with.
                </label>{" "}
                <br />
                <p>
                  <span>5.2</span> Should the Disbursement Date fall on a
                  non-working day or a public holiday, the Facility Amount will
                  be disbursed to the Borrower on the next Business Day.
                </p>
                <p>
                  <span>5.3</span> Without prejudice to any other rights or
                  remedies available to Lender, via Lender hereunder, in the
                  event that the amount received by the Borrower will cause the
                  Facility Amount to be exceeded for any reason whatsoever,
                  Lender shall have the right to recall and demand for a refund
                  of such Facility Amount exceeding the Facility Amount together
                  with interest at a rate to be determined by Lender.
                </p>
              </li>
              <li>
                <h3>FEES & CHARGES</h3>
                <label>
                  {" "}
                  <span>6.1</span> In consideration of Lender granting the
                  Facility to the Borrower, the Borrower agrees to pay all the
                  following fees to Lender:
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  {" "}
                  <span>6.1.1</span> A non-refundable Processing Fees in the
                  amount / percentage stated in the Letter of Offer (
                  <span>“Processing Fee”</span>) to be paid prior to the
                  disbursement of the Facility;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>6.1.2</span> Legal Fee to be paid prior to the
                  disbursement of the Facility;
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>6.1.3</span> Stamp duty, trustee fees (if applicable),
                  taxes and registration fees incurred and/or any other legal
                  fees and disbursements, consultation fees and professional
                  opinion fees incurred and/or chargeable pursuant to the
                  perfection of any Security, any Security Documents as well as
                  any Finance Document within
                  <span>seven (7) days</span> from the date of invoice; and
                  (collectively, <span>“Fees & Charges”</span>
                  )in such payment method or manner and place as advised,
                  instructed or determined by Lender failing which Lender
                  reserves the right to set-off any outstanding Fees & Charges
                  pursuant to Clause 11 (Set-off, Lien and Right to Debit)
                  below.
                </label>{" "}
                <br />
              </li>
              <li>
                <h3>INTEREST</h3>
                <label>
                  <span>7.1</span> The Borrower agrees that interest at the
                  prescribed Interest Rate as set out in the Letter of Offer
                  shall be calculated on the Facility Amount by reference to the
                  Instalment Period in the manner set out in this Agreement.
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  <span>7.2</span> The commencement of the interest charged on
                  the Facility Amount will be effective from commencement date
                  of the Instalment Period and thereafter be paid by the
                  Borrower to Lender on each Instalment Payment Date until the
                  entire sums and amounts due and payable under the Finance
                  Documents have been repaid to the satisfaction of the Lender.
                </label>
              </li>
              <li>
                <h3>LATE PAYMENT INTEREST</h3>
                <label>
                  {" "}
                  <span>8.1</span> In the event the Borrower fails to pay any
                  part of the respective Instalments of Facility Amount and
                  interest on or before the respective Due Date(s), the Borrower
                  agrees that Late Payment Interest at the prescribed rate as
                  set out in the Letter of Offer (
                  <span>“Late Payment Interest”</span>) will start to accrue,
                  payable immediately on the unpaid Instalment amount and
                  interest from the day after the Instalment Payment Date up to
                  the actual receipt of the Instalment, interest and Late
                  Payment Interest by Lender.
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  {" "}
                  <span>8.2</span> Further, Lender reserves the right to charge
                  an additional Penalty Fee as set out in the Letter of Offer
                  pursuant to any late payment of Instalment or interest due,
                  which will be chargeable from the day after Instalment Payment
                  Date and thereafter, upon the expiry of every
                  <span> seven (7) days</span> cycle thereafter, so long as the
                  relevant Instalment or interest remain outstanding, up to the
                  day of settlement of the same.
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  {" "}
                  <span>8.3</span> The Borrower agrees that in the event the
                  Borrower fails to pay any Fees & Charges or any other
                  outstanding sum or any other outstanding sum which may be
                  charged or has been incurred by Lender pursuant to this
                  Facility or arising from any Finance Documents (
                  <span>“Outstanding Charges”</span> ), on its respective Due
                  Date (whether demanded or not), a Late Payment Interest at the
                  prescribed rate as set out in this Letter of Offer and in the
                  manner as set out in this Agreement and such Outstanding
                  Charges accruing from the day such Outstanding Charges becomes
                  due and payable up to the date of actual receipt of the
                  Outstanding Charges together with Late Payment Interest (both
                  before as well as after demand or judgment and irrespective of
                  whether Lender-Borrower relationship exists or has been
                  terminated) from the Borrower subject to Lender’ right of
                  variation under Clause 3 (Review and Restructuring) and Clause
                  34 (Variation of Terms) in addition to and without prejudice
                  to any other rights or remedies which Lender may be entitled
                  to.
                </label>
              </li>
              <li>
                <h3>REPAYMENT</h3>
                <p>
                  {" "}
                  <span>9.1</span> The Borrower agrees to repay or cause to be
                  repaid the amount payable per instalment on each of the
                  Instalment Payment Dates in the manner prescribed under the
                  Letter of Offer subject to the terms and conditions of this
                  Agreement. Notwithstanding this, Lender shall be entitled at
                  any time to vary at its absolute discretion: (a) the number of
                  instalments; (b) the instalment amount; or (c) both.
                </p>
                <p>
                  {" "}
                  <span>9.2</span> Without prejudice to Clause 9.1 above, the
                  Borrower agrees that an acceptance of the Facility Amount
                  disbursed is deemed to be an acceptance of the obligation to
                  repay the Indebtedness on or before the Due Date(s) subject to
                  the terms and conditions of the Finance Document.
                </p>
                <p>
                  <span>9.3</span> The repayment of the Indebtedness or any part
                  thereof shall be made to or credited into the Lender Account
                  the details of which are provided in the Letter of Offer
                  (unless directed otherwise by Lender) by no later than 3 p.m.
                  on the respective Due Date (or each Due Dates, as the case may
                  be). In the event the Due Date falls on a non-Business Day,
                  the Due Date shall be extended to the next Business Day of
                  that calendar month (if there is one) or the preceding
                  Business Day (if there is none).
                </p>
                <p>
                  {" "}
                  <span>9.4</span> If repayment of any part of the outstanding
                  Repayment Amount in respect of the respective instalments is
                  not made on or before the respective Due Date, Clause 8 (Late
                  Payment Interest) will apply.
                </p>
                <p>
                  {" "}
                  <span>9.5</span> Notwithstanding the provision in Clause 9.3
                  above, the Borrower agrees that Lender reserves the rights, at
                  its sole discretion to request the Borrower to set up and
                  maintain a separate bank account (
                  <span>“Designated Account”</span>) for the purposes of
                  receiving any funds and/or payments pursuant to the Facility.
                  The costs, expenses and service fee for the setting up and
                  maintenance of such Designated Account shall be borne by the
                  Borrower.
                </p>

                <p>
                  <span>9.6</span> The Borrower authorises Lender to utilise the
                  available funds in the Designated Account to repay or set-off
                  against any outstanding Repayment Amount or Indebtedness, to
                  the Lender.
                </p>

                <p>
                  <span>9.7</span> For the avoidance of doubt, the Indebtedness
                  shall be repaid in the currency of SGD unless the prior
                  written consent of Lender that any other currency is
                  acceptable has been obtained.
                </p>

                <p>
                  <span>9.8</span> If a finance document does not set out when a
                  particular payment is due, that payment will be due within 2
                  Business Days (or such other date Lender may specify) after
                  receiving a demand from Lender.
                </p>
              </li>
              <li>
                <h3>PREPAYMENT</h3>
                <p>
                  {" "}
                  <span>10.1</span> The Borrower may prepay the Facility Amount
                  in whole or in part provided that:
                </p>
                <label>
                  <span>10.1.1</span> The Borrower pays all interest (whether
                  capitalised or not) accrued on the Facility Amount up to the
                  month of the prepayment;
                </label>{" "}
                <br />
                <label>
                  <span>10.1.2</span> The Borrower pays the Facility Amount due
                  up to the month of the prepayment;
                  <br />
                </label>
                <label>
                  <span>10.1.3</span> The Borrower pays outstanding Facility
                  Amount (<span>“Prepayment Amount”</span>);
                  <br />
                </label>
                <label>
                  {" "}
                  <span>10.1.4</span> The Prepayment Amount is round up or down
                  in the denomination of SGD100;
                </label>
                <br />
                <label>
                  {" "}
                  <span>10.1.5</span> The Borrower has given Lender not less
                  than <span className="termsUnderline">thirty (30)</span>
                  days written notice stating the Facility Amount to be prepaid
                  or in lieu of notice, a late notice fee of{" "}
                  <span className="termsUnderline">one (1)</span>
                  month interest calculated on the principal amount to be
                  prepaid; and
                </label>{" "}
                <br />
                <label>
                  {" "}
                  <span>10.1.6</span> The Borrower shall pay to Lender a
                  Prepayment Fee as specified in the Letter of Offer (
                  <span>“Prepayment Fee”</span>).
                </label>{" "}
                <br />
                <br />
                <p>
                  {" "}
                  <span>10.2</span> Any notice given under Clause 10.1 above
                  shall be irrevocable. The Prepayment Amount and Prepayment Fee
                  shall become due and payable on the date of prepayment
                  specified in the relevant notice subjected to the notice
                  period and late notice fee Clause 10.1.6.
                </p>
                <p>
                  <span>10.3</span> Any amount of the Facility Amount which is
                  prepaid under this clause may not be redrawn and shall be
                  applied towards the Borrower’s payment in inverse order of
                  maturity.
                </p>
              </li>
              <li>
                <h3>SET-OFF, LIEN AND RIGHT TO DEBIT</h3>
                <p>
                  <span>11.1</span> The Borrower agrees and authorises Lender to
                  set-off any obligation or amount due and owing from the
                  Borrower or Guarantor(s) to (a) Lender and/or (b) the Lender
                  against any obligation owed by (a) Lender to such Borrower or
                  Guarantor in any or all of the following manner simultaneously
                  (where applicable): <br />
                  <br />{" "}
                  <label>
                    {" "}
                    <span>11.1.1</span> by way of setting off against the
                    available funds in the Designated Account (if
                    applicable);and/or
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>11.1.2</span> by way of setting off against any amount
                    paid by a third party to the Borrower and/or the
                    Guarantor(s) and/or Lender; and/or
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>11.1.3</span> by way of setting off against any
                    Facility Amount to be disbursed to the Borrower;and/or
                  </label>{" "}
                  <br />
                  <label>
                    {" "}
                    <span>11.1.4</span> by way of direct-debit GIRO from the
                    Borrower’s bank accounts and/or Guarantor’s bank accounts,
                  </label>{" "}
                  <br />
                  <br />{" "}
                  <label>
                    without further reference or notice to the Borrower and/or
                    the Guarantor.
                  </label>
                </p>

                <p>
                  <span>11.2</span> If the amount of any such obligation is in a
                  different currency, Lender may convert either obligation at a
                  market rate of exchange in its usual course of business for
                  the purpose of the set-off.
                </p>
                <p>
                  <span>11.3</span> For the purpose of this clause, “obligation”
                  includes any obligation whether matured or unmatured, actual
                  or contingent, present or future. If the amount of any such
                  obligation is unascertained, Lender may estimate the amount
                  for the purposes of the set-off.
                </p>

                <p>
                  <span>11.4</span> Any exercise by Lender of its rights under
                  this clause shall not limit or affect any other rights or
                  remedies available to it under this Agreement or otherwise.
                </p>
              </li>
              <li>
                <h3>EVENTS OF DEFAULT</h3>
                <p>
                  <span>12.1</span> In addition, and without prejudice to the
                  rights of Lender as provided in Clause 3 (Review and
                  Restructuring) and Clause 34 (Variation of Terms) of this
                  Agreement, the Borrower hereby agrees and acknowledges that
                  the Facility may either be terminated and the aggregate
                  Repayment Amount outstanding under the Facility with interest,
                  Fees & Charges, Penalty Fee and/or Late Payment Interest
                  thereon and any other monies herein covenanted to be paid or
                  intended to be secured hereunder shall become due and
                  immediately payable whether formally demanded or not or where
                  applicable, may be withdrawn and not be made available to the
                  Borrower notwithstanding that the Finance Documents have been
                  executed and perfected upon the happening of any of the
                  following events (<span>“Events of Default”</span>): <br />
                  <br />{" "}
                  <label>
                    <span>12.1.1.</span> if the Borrower fails to pay any sums
                    payable by the Borrower hereunder and/or the Finance
                    Documents i.e. any one or more of the instalments pursuant
                    to the Instalment Payment Date, Late Payment Interest,
                    Penalty Fee, Prepayment Fee, Fees & Charges thereon or other
                    sums or monies herein agreed by the Borrower to be paid
                    after the same shall have become due;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.2.</span> if the Guarantor(s) fails to pay any
                    sums payable by the Guarantor(s) hereunder and/or the
                    Finance Documents after the same shall have become due;
                  </label>
                  <br />
                  <label>
                    <span>12.1.3.</span> if any of the Obligors fail to observe
                    or perform any of the agreements, terms, undertakings,
                    covenants and conditions herein and/or Finance Documents;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.4.</span> if there is a reasonable suspicion that
                    the Borrower may fail to pay any sums payable hereunder
                    and/or the Finance Documents;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.5.</span> if any of the Obligors create or permit
                    to subsist any mortgage, pledge, charge, encumbrance, lien
                    or security interest in the asset offered as security under
                    the Finance Documents without the prior written consent of
                    Lender (if applicable);
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.6.</span> if any of the Obligors cease or
                    threaten to cease to carry on its businesses (if
                    applicable);
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.7.</span> (A) if a Winding Up petition or
                    application has been presented against any of the Obligors
                    or (B) if any of the Obligors (i) commit any act of
                    bankruptcy or (ii) becomes bankrupt or (iii) enters into
                    liquidation or (iv) has an order of court made for its
                    compulsory liquidation, or (v) it enters into a discussion
                    of any composition or arrangement with or for the benefit of
                    the creditors of such Obligors, or (v) has a judicial
                    manager, receiver or manager (including temporary or interim
                    officials) appointed over the whole of or any part of its
                    assets or undertakings, or (vi) allow any judgment against
                    any of the Obligors to remain unsatisfied for a period of
                    twenty one (21) days or (vii) has any distress or execution
                    or other process of a Court of competent jurisdiction levied
                    upon or issued against any property of any of the Obligors
                    and such distress or execution or other process, as the case
                    may be, is not satisfied by the Obligors within seven (7)
                    days thereof, or (viiii) causes or becomes subject to or
                    takes any action in furtherance or indicates its consent to,
                    any event with respect to which, under the applicable laws
                    of any jurisdiction, has an analogous effect to any of these
                    events;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.8.</span> the happening of any event which in the
                    opinion of Lender would effect in any way the financial
                    position of any of the Obligors and/or that its security
                    under this Agreement and/or Finance Documents is in jeopardy
                    and notice thereof is given to any of the Obligors or that
                    any event occurred or a situation exists which could or
                    might prejudice the Obligors’ ability to perform his or
                    their obligations under this Agreement;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.9.</span> if at any time it becomes unlawful for
                    any of the Obligors to perform the obligations under this
                    Agreement;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.10.</span> if there is any legal proceedings or
                    suits instituted or threatened to be instituted by any
                    person against any of the Obligors;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.11.</span> if any of the Security Documents or
                    this Agreement cannot be perfected for any reason whatsoever
                    or cannot be registered where necessary or becomes invalid
                    or is repudiated for any reason whatsoever;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.12.</span> if a change in applicable law or
                    regulations makes it impossible or unlawful for Lender to
                    continue its making available the Facility or any part
                    thereof to the Borrower and/or for any of the Obligors to
                    comply with any obligations under this Agreement or Security
                    Documents;
                  </label>{" "}
                  <br />
                  <label>
                    <span>12.1.13.</span> if any of the Obligors shall be
                    affected by a material change in his or their financial
                    conditions which in the opinion of Lender is likely to
                    prejudice the ability of the Obligors to perform his or
                    their obligations under this Agreement and/or the Finance
                    Documents in accordance with the terms thereof;
                  </label>{" "}
                  <br />
                  <span>12.1.14.</span>{" "}
                  <label>
                    {" "}
                    if there shall have been any such change in national or
                    international financial or economic conditions or currency
                    exchange rates or exchange control which in the opinion of
                    Lender will affect any of the Obligors’ ability to perform
                    his or their obligations;
                  </label>{" "}
                  <br />
                  <span>12.1.15.</span>{" "}
                  <label>
                    {" "}
                    if any of the Obligors fail to pay any sums due and payable
                    by any of them in any other facility or agreement with
                    Lender after the same shall have become due whether demanded
                    or not;
                  </label>{" "}
                  <br />
                  <span>12.1.16.</span>{" "}
                  <label>
                    {" "}
                    if any of the Obligors commit any default, event of default
                    or other similar condition or event (however described) in
                    respect of any loan agreements, arrangements, debentures,
                    credit agreements, agreements, indemnification or other
                    financial instruments (collectively “Other Financing
                    Arrangements”) and such default, event or condition has
                    resulted in Other Financing Arrangements becoming, or
                    becoming capable of being declared due and payable
                    thereunder;
                  </label>{" "}
                  <br />
                  <span>12.1.17.</span>{" "}
                  <label>
                    {" "}
                    if any of the Obligors enter into any form of merger,
                    reconstruction, consolidation or amalgamation by way of a
                    scheme of arrangement or otherwise which is detrimental to
                    the performance of the Obligors’ obligations under this
                    Agreement;
                  </label>{" "}
                  <br />
                  <span>12.1.18.</span>{" "}
                  <label>
                    {" "}
                    if any of the Obligors become insane and/or die;
                  </label>{" "}
                  <br />
                  <span>12.1.19.</span>{" "}
                  <label>
                    {" "}
                    if any of the Obligors become uncontactable or unreachable
                    via phone, physical visit, e-mail or any other reasonable
                    means by Lender;
                  </label>{" "}
                  <br />
                  <span>12.1.20.</span>{" "}
                  <label>
                    {" "}
                    if any representation or statement made or deemed to be made
                    by any Obligor in this Agreement or any other document
                    delivered by or on behalf of any Obligor under or in
                    connection with this Agreement is or proves to have been
                    incorrect or misleading when made or deemed to be made; or
                  </label>{" "}
                  <br />
                  <span>12.1.21.</span>{" "}
                  <label>
                    {" "}
                    all or a material part of the assets of any of the Obligors
                    are seized, compulsorily acquired, expropriated or
                    nationalized; or
                  </label>{" "}
                  <br />
                  <span>12.1.22.</span>{" "}
                  <label>
                    {" "}
                    if any of the Obligors contravene any of the provisions of
                    the AMLA.
                  </label>
                  <br />
                  <span>12.1.23.</span>{" "}
                  <label>
                    {" "}
                    Any Obligor is or is likely to become or is presumed or
                    deemed to be unable or admits inability to pay its debts as
                    they fall due, suspends making payments on any of its debts
                    or, by reason of actual or anticipated financial
                    difficulties, commences negotiations with one or more of its
                    creditors with a view to rescheduling any of its
                    indebtedness.
                  </label>
                  <br />
                  <span>12.1.24.</span>{" "}
                  <label>
                    {" "}
                    the value of the assets of any Obligor is less than its
                    liabilities (taking into account contingent and prospective
                    liabilities).
                  </label>
                  <br />
                  <span>12.1.25.</span>{" "}
                  <label>
                    {" "}
                    A moratorium is declared or comes into effect in respect of
                    any indebtedness of any Obligor.
                  </label>
                  <br />
                </p>
                <span>12.2</span>
                <label>
                  {" "}
                  Each Obligor hereby undertakes to notify Lender immediately
                  upon becoming aware of the occurrence or the anticipation of
                  occurrence of any Events of Default.
                </label>
                <br />
                <br /> <span>12.3</span>
                <label>
                  {" "}
                  If any of the Event of Default specified in clauses 12.1.1,
                  12.1.2, 12.1.5, 12.1.6, 12.1.7, 12.1.11 and 12.1.15 above
                  occurs, the Lender shall, immediately upon the occurrence of
                  any such Event of Default, be entitled forthwith to take such
                  actions as may be appropriate against any of the Obligors, and
                  the Lender shall be entitled to exercise all or any of its
                  rights, powers, remedies or discretions available under any of
                  the Finance Documents, including commencing legal proceedings
                  against the Obligors and the costs and expenses of such
                  proceedings shall be borne by the Obligors.
                </label>
                <p>
                  <br /> <span>12.4</span> If any of the Event of Default (other
                  than as specified in Clause 12.3 above) occurs pursuant to
                  Clause 12 (Events of Default) hereof, the Obligors shall have{" "}
                  <span className="termsUnderline">
                    a period of seven (7) days
                  </span>{" "}
                  from the date of occurrence of any such event to remedy the
                  same failing which Lender shall be entitled forthwith to take
                  such actions as may be appropriate against any of the
                  Obligors, and the Lender shall be entitled to exercise all or
                  any of its rights, powers, remedies or discretions available
                  under any of the Finance Documents, including commencing legal
                  proceedings against the Obligors and the costs and expenses of
                  such proceedings shall be borne by the Obligors.
                </p>
                <p>
                  <span>12.5</span> On and at any time prior to or after the
                  occurrence of an Event of Default or if Lender has reasonable
                  grounds to suspect that an Event of Default may occur,
                  regardless of whether a notice has been issued and regardless
                  of whether the Facility had been terminated, Lender shall
                  forthwith become entitled to recover the total Indebtedness
                  from the Borrower and to exercise the following rights and
                  powers without any notice to the Borrower which may include
                  but not limited to any of the following wherein the costs of
                  the same shall be fully indemnified by the Borrower and/or
                  Obligors (<span>“Debt Recovery Exercise”</span>): <br />
                  <br /> <span>12.5.1</span>{" "}
                  <label>
                    {" "}
                    By notice to the Borrower, the right to cancel the
                    outstanding Facility Amount (whereupon it shall be
                    immediately cancelled), and/or declare all outstanding
                    Indebtedness under the Finance Documents be immediately due
                    and payable (whereupon they shall become immediately due and
                    payable);
                  </label>{" "}
                  <br />
                  <span>12.5.2</span>{" "}
                  <label>
                    {" "}
                    The right to enforce, perfect and/or take possession of any
                    Security or any part or parts thereof;
                  </label>{" "}
                  <br />
                  <span>12.5.3</span>{" "}
                  <label>
                    {" "}
                    The right to appoint a debt collection agency, to recover
                    the amount that is owed by the Borrower to Lender;
                  </label>
                  <br />
                  <span>12.5.4</span>{" "}
                  <label>
                    {" "}
                    The right and power to sell, foreclose and assign the
                    Security or any part thereof at such price or prices and in
                    such manner as Lender shall in its absolute discretion think
                    fit and free from interest from the Borrower and the other
                    security party hereunder or otherwise and to apply the
                    proceeds of sale towards the satisfaction of the
                    Indebtedness and other monies due to the Lender hereunder;
                  </label>
                  <br />
                  <span>12.5.5</span>{" "}
                  <label>
                    {" "}
                    The right to institute such legal proceedings and take steps
                    (including any proceedings for the realisation of its
                    security under any of the Security Documents) as it may
                    think fit to enforce payment of all monies owing to Lender;
                    and/or
                  </label>
                  <br />
                  <span>12.5.6</span> <label> Enforce the Guarantee;</label>
                  <br />
                  <span>12.5.7</span>{" "}
                  <label>
                    {" "}
                    Sell or foreclose any assets of the Borrower held by Lender
                    on deposit, security or otherwise, by public or private sale
                    without any judicial proceedings whatsoever, and retain from
                    the proceeds derived therefrom the total amount remaining
                    unpaid and all costs charges and expenses incidental to such
                    sale;
                  </label>
                  <br />
                  <p>
                    <br /> <span>PROVIDED THAT</span>nothing herein contained
                    shall be construed as imposing any obligation (whether at
                    law or in equity) upon Lender to exhaust its remedy to sell
                    the Security (or any part thereof) before commencing any
                    legal action or before enforcing any other remedies or
                    exercising any other rights against the Obligors who hereby
                    irrevocably and unconditionally agree and consent to Lender
                    commencing concurrent and separate proceedings, enforcing
                    other remedies and exercising any other rights which Lender
                    may have against the Obligors prior to or simultaneously
                    with and without having exhausted its rights to sell or to
                    proceed against the Security or to realise its security
                    under the documents creating security for this Agreement and
                    Lender shall also be entitled to exercise such remedies
                    concurrently.
                  </p>
                </p>
                <p>
                  <span>12.6</span> The Obligors shall and hereby expressly
                  agree, covenant and undertake to do and execute all acts,
                  deeds, instruments and things which Lender may require or
                  stipulate for the purposes of affecting and or completing
                  anything and or any transaction mentioned in this clause and
                  the Obligors shall be liable to indemnify the Lender all such
                  costs and expenses incurred pursuant to this clause.
                </p>
                <p>
                  <span>12.7</span> Notwithstanding the above, the Obligors are
                  jointly and severally liable to the costs and expenses of any
                  such legal proceedings commenced by the Lender on a solicitor
                  and client basis.
                </p>
              </li>
              <li>
                <h3>CROSS DEFAULT</h3>
                <span>13.1</span>
                <label>
                  The Obligors hereby expressly agree that if any sums shall be
                  due from the Obligors to Lender and any other financier or
                  party from time to time or at any time or if the Obligors may
                  be or become liable to the Borrower and any other financier or
                  party on any account in any manner whatsoever or if default is
                  made in any provisions of such accounts or in any other
                  facilities granted by Lender and any other financier or party
                  to the Obligors or in any of the provisions herein, then and
                  in such event the entire Indebtedness herein together with all
                  monies payable under such accounts or other facilities granted
                  by Lender to the Borrower shall immediately become due and
                  payable and the Security herein shall become immediately
                  enforceable as security for all monies and liabilities
                  whatsoever now or hereafter from time to time owing or payable
                  to Lender by the Obligors whether alone or jointly and
                  severally with another or others and whether as principal or
                  surety notwithstanding that the Obligors may at any time or
                  time(s) cease to be indebted to Lender for any period or
                  periods and notwithstanding any settlement of account(s) or
                  otherwise.
                </label>
              </li>
              <li>
                <h3>APPLICATION OF PROCEEDS</h3>
                <p>
                  All monies recovered or received by from any proceedings
                  instituted or steps taken under this Agreement (including
                  without limitation under Clause 9 (Repayment)), shall be
                  applied by Lender in the following manner: <br />
                  <br /> <span>14.1.1 </span>{" "}
                  <label>
                    <span className="termsUnderline"> Firstly,</span>
                    in or towards all fees, costs, expenses and liabilities
                    incurred by the Lender in realising any Security and all
                    monies payable by the Obligors to the Lender and in carrying
                    out Debt Recovery Exercises, enforcement actions,
                    proceedings and/or steps in enforcing the terms of this
                    Agreement and Finance Documents;
                  </label>{" "}
                  <br />
                  <span>14.1.2 </span>{" "}
                  <label>
                    {" "}
                    <span className="termsUnderline"> Secondly,</span>
                    in or towards payment of the Facility Amount due and
                    remaining unpaid under the Facility;
                  </label>{" "}
                  <br />
                  <span>14.1.3 </span>{" "}
                  <label>
                    {" "}
                    <span className="termsUnderline"> Thirdly,</span>
                    in or towards payment of the interests accrued which is due
                    and remaining unpaid under the Facility;
                  </label>{" "}
                  <br />
                  <span>14.1.4 </span>{" "}
                  <label>
                    {" "}
                    <span className="termsUnderline"> Fourthly,</span>
                    in or towards payment of all Fees & Charges, Late Payment
                    Interest Penalty Fee and charges then accrued and remaining
                    unpaid in respect of the Facility or the balance there of;
                  </label>{" "}
                  <br />
                  <span>14.1.5 </span>{" "}
                  <label>
                    {" "}
                    <span className="termsUnderline"> Fifthly,</span>
                    in or towards payment to the Lender all other moneys due and
                    remaining unpaid under any or all of the Finance Documents;
                    and
                  </label>{" "}
                  <br />
                  <span>14.1.6 </span>{" "}
                  <label>
                    {" "}
                    Lastly, in or towards payment of any surplus thereafter, to
                    the Borrower and/or such persons entitled thereto;
                  </label>{" "}
                  <br />
                  <p>
                    Provided always that if Lender shall be of the opinion that
                    the security may prove deficient payments, Lender may alter
                    the above order of payment but such alteration in the order
                    of payment shall not prejudice the right of Lender to
                    receive the full amount to which it would have been entitled
                    if the primary order of payment had been observed or any
                    lesser amount which the sum ultimately realized from the
                    security may be sufficient to pay.
                  </p>
                  <span>14.2 </span>
                  <label>
                    {" "}
                    If the amount recovered pursuant to any of the Debt Recovery
                    Exercise is insufficient to settle the total Indebtedness of
                    any of the Obligors, then the Obligors shall pay to Lender
                    the difference between the total Indebtedness and the amount
                    recovered of which is subject to the accrual of interest at
                    the prescribed rate stated in the Letter of Offer and an
                    additional Late Payment Interest (whichever is applicable)
                    as stated in the Letter of Offer until the full settlement
                    of the same.
                  </label>
                </p>
              </li>
              <li>
                <h3>GUARANTEE AND INDEMNITY</h3>
                <span>15.1 </span>
                <label>
                  {" "}
                  In consideration of Lender granting the Facility to the
                  Borrower, each of the Guarantor(s) irrevocably and
                  unconditionally, jointly and severally (
                  <span>“Guarantee”</span>):
                  <br />
                  <br /> <span>15.1.1 </span>{" "}
                  <label>
                    {" "}
                    Guarantee to Lender as a primary obligation the due and
                    punctual payment of each and every Guaranteed Obligation or
                    any unpaid portion thereof whether at stated maturity, upon
                    acceleration or otherwise;
                  </label>{" "}
                  <br />
                  <span>15.1.2 </span>{" "}
                  <label>
                    {" "}
                    Undertakes that whenever the Borrower does not pay any
                    amount of the Guaranteed Obligations when due, the Guarantor
                    will, upon demand by Lender, pay that amount to Lender, in
                    the currency, in the same manner in all respects as such
                    Guaranteed Obligations are required to be paid by the
                    Borrower under this Agreement and other Finance Documents;
                  </label>
                  <br />
                  <span>15.1.3 </span>{" "}
                  <label>
                    {" "}
                    Agrees, as a primary obligation, to indemnify the Lender
                    from time to time on demand from and against any costs,
                    damages, expenses, claims and demands of whatever nature
                    which they may incur or sustain or which may result from the
                    non- performance or breach of the Borrower of any of its
                    obligations under this Agreement and Finance Document
                  </label>
                  <br />
                  <span>15.1.4 </span>{" "}
                  <label>
                    {" "}
                    Agree to pay to the Lender the continuing interests on the
                    Interest Rate pursuant to this Agreement on the Facility
                    Amount or any part thereof for the time being unpaid and due
                    to the Lender under this Agreement and until full payment;
                  </label>
                  <br />
                  <span>15.1.5 </span>{" "}
                  <label>
                    {" "}
                    Agree to pay to the Lender costs, charges and expenses
                    incurred by Lender in enforcing or in endeavouring to
                    enforce the payment of any money due under this Agreement.
                    The Guarantor(s) also unconditionally agree that all the
                    legal bill for fees and costs so drawn up by the solicitors
                    of the Lender shall be deemed accepted by the Guarantors and
                    not subject to any dispute nor objection whatsoever;
                  </label>
                  <br />
                  <span>15.1.6 </span>{" "}
                  <label>
                    {" "}
                    Agrees that if any obligation guaranteed becomes
                    unenforceable, invalid or illegal, the Guarantors will, as
                    an independent and primary Obligor, indemnify the Lender
                    immediately on demand against any cost, expense, loss or
                    liability that Lender incur as a result of an Obligor not
                    paying any amount which would, but for such
                    unenforceability, invalidity or illegality, have been
                    payable by it under any Finance Document on the date when it
                    would have been due;
                  </label>
                  <br />
                  <span>15.1.7 </span>{" "}
                  <label>
                    {" "}
                    Agree that this Guarantee shall not be prejudiced nor
                    affected by any indulgence or variation in the Facility or
                    any release of security, guarantee or rights or any release
                    or failure to enforce any right against the Borrower. Any
                    Security held by the Borrower and/or the Guarantors in
                    respect of any liabilities of the Borrower pursuant to this
                    Agreement shall be held on trust for the benefit of Lender
                    which will be deposited with Lender upon the demand at the
                    Guarantors’ costs and expenses;
                  </label>
                  <br />
                  <span>15.1.8 </span>{" "}
                  <label>
                    {" "}
                    Agree that this Guarantee is a continuing Guarantee and will
                    extend to the ultimate balance of sums payable by any
                    Obligor under the Finance Documents regardless of any
                    intermediate payment or discharge in whole or in part;
                  </label>
                  <br />
                  <span>15.1.9 </span>{" "}
                  <label>
                    {" "}
                    Agree that this Guarantee shall be in addition to and not in
                    substitution for any other securities, collateral,
                    guarantees and indemnities under this Agreement and may be
                    enforced against the Guarantors without first having
                    recourse to any such securities or rights and without first
                    having recourse to any legal steps or court proceedings
                    against the Borrower or any other person or persons;
                  </label>
                  <br />
                  <span>15.1.10 </span>{" "}
                  <label>
                    {" "}
                    Agree to give effect to this Guarantee that the Lender will
                    be at liberty to act as though each of the Guarantors are
                    principal debtors to the Lender all the monies guaranteed by
                    the Guarantors aforesaid and notwithstanding: <br />
                    <span>(a) </span>{" "}
                    <label>
                      {" "}
                      the failure of any one of the Guarantors to complete or
                      sign this Guarantee;{" "}
                    </label>{" "}
                    <br />
                    <span>(b) </span>{" "}
                    <label>
                      {" "}
                      the fact that for any reason whatsoever the signature of
                      any one or more of the Guarantors shall not bind his or
                      her estate;{" "}
                    </label>{" "}
                    <br />
                    <span>(c) </span>{" "}
                    <label>
                      {" "}
                      the release or discharge by the Lender of any one or more
                      of the Guarantor from liability under this Guarantee; or{" "}
                    </label>{" "}
                    <br />
                    <span>(d) </span>{" "}
                    <label>
                      {" "}
                      the release or discharge by the Lender of the Borrower
                      from further liability under this Agreement and Finance
                      Document;{" "}
                    </label>{" "}
                    <br />
                    <label>
                      the remainder of the Guarantors shall continue to be bound
                      by this Guarantee and it shall be and remain a continuing
                      security as to the other Guarantors;
                    </label>
                  </label>
                  <br />
                  <span>15.1.11 </span>{" "}
                  <label>
                    {" "}
                    Agree that all monies received from or on account of the
                    Borrower or from any other person or estate or from the
                    realisation of any Security or otherwise for the purpose of
                    being applied in reduction of the monies aforesaid shall be
                    treated for all purposes in gross and not as appropriated or
                    attributable to any specific part of the said money even if
                    appropriated thereto by the person otherwise so entitled;
                  </label>
                  <br />
                  <span>15.1.12 </span>{" "}
                  <label>
                    {" "}
                    Agree that the Guarantors’ obligations hereunder shall not
                    be discharged except by performance and then only to the
                    extent of such performance. Such obligations shall not be
                    subject to any prior notice to or demand to any one of the
                    Guarantors with regard to any default of the Borrower and
                    shall not be impaired by any extension of time, forbearance
                    or concession given to the Borrower or any failure to assert
                    any security created by or any failure of the Borrower to
                    comply with any regulation or order in Singapore;
                  </label>
                  <br />
                  <span>15.1.13 </span>{" "}
                  <label>
                    {" "}
                    Agree that so long as the Facility is outstanding and not
                    fully paid this Guarantee shall be a continuing Guarantee
                    and shall be irrevocable notwithstanding the common law
                    right of revoking continuing guarantee. The Guarantors’
                    obligations to the Lender hereunder shall not be considered
                    as satisfied by any intermediate payment or satisfaction of
                    the whole or any part of the sums of money owing as
                    aforesaid but shall be a continuing security which shall
                    extend to cover any sums of money which shall for the time
                    being constitute the amount due from the Borrower under this
                    Agreement;
                  </label>
                  <br />
                  <span>15.1.14 </span>{" "}
                  <label>
                    {" "}
                    Agree that the security/guarantee created by this Guarantee
                    is expressly intended to be and shall be a continuing
                    security for all monies and liabilities whatsoever now or at
                    any time hereafter owing or otherwise payable by the
                    Borrower to the Lender whether jointly and severally with
                    another and whether as principal or surety notwithstanding
                    that the Borrower may at any time cease to be indebted to
                    Lender for any period and notwithstanding that the Facility,
                    account or accounts of the Borrower with Lender and/or the
                    trustee may from any cause whatsoever cease to be operative
                    and notwithstanding any settlement of such account or
                    accounts otherwise;
                  </label>
                  <br />
                  <span>15.1.15 </span>{" "}
                  <label>
                    {" "}
                    Agree that all sums of money which may not be recoverable
                    from the Guarantors by reason of any legal limitations,
                    liability or incapacity on or of the Borrower, including
                    (without limitation) any defect informality or deficiency or
                    insufficiency in the borrowing powers of the Borrower or in
                    the exercise thereof which might be a defence as between the
                    Borrower and Lender shall nevertheless be recoverable from
                    the Guarantors as sole or principal debtors;
                  </label>
                  <br />
                  <span>15.1.16 </span>{" "}
                  <label>
                    {" "}
                    Agree to waive all surety and other rights which but for
                    this waiver the Guarantors might be entitled to;
                  </label>
                  <br />
                  <span>15.1.17 </span>{" "}
                  <label>
                    {" "}
                    Agree that this Guarantee shall be binding upon the
                    Guarantors’ heirs, personal representatives, permitted
                    assigns and successors-in-title (as the case may be);
                  </label>
                  <br />
                  <span>15.1.18 </span>{" "}
                  <label>
                    {" "}
                    Agree that should the Borrower be liquidated or wound up,
                    Lender may (notwithstanding payment to the Lender by any one
                    or more of the Guarantors or any other person of the whole
                    or any part of the amount hereby guaranteed) rank as
                    creditor and prove for the whole amount of the Lender’s
                    claim against the Borrower or agree, to and accept any
                    composition in respect of the same, and Lender may receive
                    and retain the whole of the dividends, composition or other
                    payments thereon to the exclusion of any of the Guarantors
                    in competition with the Borrower/Lender until the Lender
                    claim fully satisfied;
                  </label>
                  <br />
                  <span>15.1.19 </span>{" "}
                  <label>
                    {" "}
                    Agree that the Guarantors will not, by paying off the sums
                    due and owing or any part thereof, or upon any other ground,
                    prove or claim in respect of the said sums or any part
                    thereof until the whole of the Lender’s claim against the
                    Borrower has been satisfied. Further, for the purpose of
                    enabling the Lender to sue the Borrower or to prove against
                    them for the whole Indebtedness owing as aforesaid or to
                    preserve the liability of any other party, Lender or may
                    keep for such time as Lender may consider prudent any money
                    recovered or realised hereunder at a separate or suspense
                    account to the credit of the Guarantors or to the credit of
                    such other person if Lender thinks fit without any
                    obligation to apply the same or any part thereof in or
                    towards the discharge of the monies owing as aforesaid or
                    without any right on the Guarantors part to sue the Borrower
                    or to prove against the Borrower in liquidation or
                    insolvency in competition with Lender and so as to diminish
                    any dividend or other advantage that might come or to treat
                    the liability of the Borrower as diminished; and
                  </label>
                  <br />
                  <span>15.1.20 </span>{" "}
                  <label>
                    Agree that so long as any monies remain owing hereunder the
                    Lender shall have a lien therefore on all monies now or
                    hereafter standing to any one of the Guarantors accounts
                    whether with Lender or/and a lien on any stock or share
                    certificates, title deeds or other securities belonging to
                    any one of the Guarantors under Lender’ control or which
                    have been deposited with Lender for any purpose; and
                  </label>
                  <br />
                  <span>15.1.21 </span>{" "}
                  <label>
                    {" "}
                    Agree that any indebtedness of the Borrower now or hereafter
                    held by Obligors shall be fully subordinated to the
                    indebtedness of the Borrower to Lender and such indebtedness
                    of Borrower to Obligors if Lender so requires shall be
                    collected enforced and received by Obligors as trustee for
                    Lender and shall be paid over to Lender on account of the
                    indebtedness of Borrower to Lender but without reducing or
                    affecting in any manner the liability of Obligors under this
                    Guarantee or Agreement until all the Guaranteed Obligations
                    has been fully paid to Lender; and
                  </label>
                  <br />
                  <span>15.1.22 </span>{" "}
                  <label>
                    {" "}
                    if any GST is chargeable by law on any payment hereunder,
                    Guarantors shall pay such GST in addition to all other sums
                    payable hereunder or relating hereto and agrees to indemnify
                    Lender against the payment if Lender is required by law to
                    collect and make payment of suchGST.
                  </label>
                  <br />
                </label>

                <p>
                  <br /> <span>15.2 </span> The obligations of the Guarantors
                  under this Guarantee will not be affected by an act, omission,
                  matter or thing which, would reduce, release or prejudice any
                  of its obligations under this Guarantee or result in any such
                  obligation not being (or ceasing to be) valid or binding
                  (without limitation and whether or not known to it or any the
                  Lender including: <br />
                  <br /> <span>15.2.1 </span>{" "}
                  <label>
                    {" "}
                    any time, waiver or consent granted to, or composition with,
                    any Obligor or other person;
                  </label>
                  <br />
                  <span>15.2.2 </span>{" "}
                  <label>
                    {" "}
                    the release of any Obligor or any other person under the
                    terms of any composition or arrangement with any creditor of
                    any Obligor;
                  </label>
                  <br />
                  <span>15.2.3 </span>{" "}
                  <label>
                    {" "}
                    the taking, variation, compromise, exchange, renewal or
                    release of, or refusal or neglect to perfect, execute, take
                    up or enforce, any rights against Security over assets of
                    any Obligor or other person or any non-presentation or non-
                    observance of any formality or other requirement in respect
                    of any instrument or any failure to realise the full value
                    of any Security aforementioned;
                  </label>
                  <br />
                  <span>15.2.4 </span>{" "}
                  <label>
                    {" "}
                    any death, mental or other incapacity or lack of power,
                    authority or legal personality of or dissolution,
                    amalgamation, taking over, merger or change in the members
                    or status or constitution of an Obligor;
                  </label>
                  <br />
                  <span>15.2.5 </span>{" "}
                  <label>
                    {" "}
                    any amendment, novation, supplement, extension, restatement
                    or replacement (in each case, however fundamental and
                    whether or not more onerous) of any Finance Document or any
                    other document or Security, including any change in the
                    purpose of, any extension of or any increase in any facility
                    or the addition of any new facility under any Finance
                    Document or other document or in relation to any Security;
                  </label>
                  <br />
                  <span>15.2.6 </span>{" "}
                  <label>
                    {" "}
                    any unenforceability, illegality or invalidity of any
                    obligation of any person under any Finance Document or any
                    other document or Security;
                  </label>
                  <br />
                  <span>15.2.7 </span>{" "}
                  <label>
                    {" "}
                    any postponement, discharge, reduction, non-provability or
                    other similar circumstance affecting any obligation of any
                    person under any Finance Document resulting from any law,
                    regulation or order;
                  </label>
                  <br />
                  <span>15.2.8 </span>{" "}
                  <label>
                    {" "}
                    any insolvency, bankruptcy, winding up, liquidation,
                    dissolution, administration, judicial management,
                    provisional supervision, arrangement, compromise,
                    amalgamation, merger, consolidation, reconstruction,
                    re-organisation or similar proceedings or any analogous
                    procedure or step in any jurisdiction; or
                  </label>
                  <br />
                  <span>15.2.9 </span>{" "}
                  <label>
                    {" "}
                    by any moratorium or other period staying or suspending by
                    statute or order of any court or other authority all or any
                    of Lender rights, remedies or recourse against Obligors;or
                  </label>
                  <br />
                  <span>15.2.10 </span>{" "}
                  <label>
                    {" "}
                    this Agreement or any Finance Document not being executed by
                    or binding upon any other party.
                  </label>
                  <br />
                </p>

                <span>15.3 </span>
                <label>
                  {" "}
                  Each Guarantor acknowledges that, in entering into this
                  Guarantee, such Guarantor: <br />
                  <br /> <span>15.3.1 </span>{" "}
                  <label>
                    {" "}
                    has not relied on anything said or written statement,
                    advice, opinion or information given by Lender or on Lender’
                    behalf and the Lender shall have no liability to such
                    Guarantor;
                  </label>
                  <br />
                  <span>15.3.2 </span>{" "}
                  <label>
                    has made its own decision, without relying on Lender, on the
                    chances of success or failure of the purpose for which each
                    Borrower has incurred the Guaranteed Obligations and the
                    Lender shall have no liability to such Guarantor;
                  </label>
                  <br />
                  <span>15.3.3 </span>{" "}
                  <label>
                    {" "}
                    has made its own independent investigation of each Borrower
                    and its affairs and financial condition and has come to its
                    own decision, without relying on Lender, as to the
                    likelihood of the Borrower repaying the Guaranteed
                    Obligations and the Lender shall have no liability to such
                    Guarantor; and
                  </label>
                  <br />
                  <span>15.3.4 </span>{" "}
                  <label>
                    {" "}
                    agrees with Lender for itself and as trustee for its
                    officials, employees and agents, that neither Lender nor its
                    officials, employees or agents shall have any liability for
                    anything which Lender, its officials, employees or agents
                    may have done or failed to do in good faith.
                  </label>
                  <br />
                </label>
              </li>
              <li>
                <h3>TERMINATION</h3>
                <p>
                  Termination of this Agreement or any Party’s obligations under
                  this Agreement shall not affect the obligations of each Party
                  under this Agreement as are intended of their nature or by
                  their terms, to continue past termination nor shall such
                  termination relieve any part of any antecedent breaches of any
                  terms and conditions of this Agreement.
                </p>
              </li>
              <li>
                <h3>OBLIGORS’ REPRESENTATION AND WARRANTIES</h3>
                <span>17.1 </span>
                <label>
                  {" "}
                  Each Obligor hereby represents, warrants, covenants and
                  undertakes to the Lender and their successor(s)-in-title and
                  permitted assignee(s) that: <br />
                  <br /> <span>17.1.1 </span>{" "}
                  <label>
                    {" "}
                    it has the necessary required power, capacity and authority
                    to enter into and perform, and in the event the Obligor is a
                    corporation, (A) it is duly incorporated and validly
                    existing under the laws of its incorporation and (B) it has
                    taken all necessary action to authorise the entry into,
                    performance and delivery of this Agreement and Finance
                    Documents and the transactions contemplated herein and that
                    any applicable internal rules have been complied with;
                  </label>{" "}
                  <br />
                  <span>17.1.2 </span>{" "}
                  <label>
                    {" "}
                    all actions, conditions and things required to be taken,
                    fulfilled and done which may include the requirement of any
                    necessary consents or licences, filing, application or
                    registration of any approval in order to enable it to
                    lawfully enter into and perform its obligations under this
                    Agreement and/or any of the Finance Documents to which it is
                    a party;
                  </label>{" "}
                  <br />
                  <span>17.1.3 </span>{" "}
                  <label>
                    {" "}
                    this Agreement has been duly authorized, executed and
                    delivered by it and constitutes its valid and legally
                    binding obligations enforceable in accordance with its
                    terms;
                  </label>{" "}
                  <br />
                  <span>17.1.4 </span>{" "}
                  <label>
                    {" "}
                    its entry into, exercise of its rights and/or performance of
                    its obligations under this Agreement and/or any of the
                    Finance Documents to which it is a party, (A) do not and
                    will not violate, or exceed any power or restriction granted
                    or imposed by any law, regulation, authorisation, directive,
                    order, articles of association, constitution, internal rules
                    (as applicable) whether or not having the force of law. to
                    which it is subject and (B) conflicts or will conflict with
                    or result in any breach of any of the terms, conditions or
                    provisions of, or violate or constitute a default or require
                    any consent under any indenture, mortgage, agreement or
                    other instrument or arrangement to which it is a party or by
                    which it is bound;
                  </label>
                  <br />
                  <span>17.1.5 </span>{" "}
                  <label>
                    {" "}
                    its obligations under this Agreement and/or any of the
                    Finance Documents to which it is a party are valid, binding
                    and enforceable in accordance with their terms;
                  </label>
                  <br />
                  <span>17.1.6 </span>{" "}
                  <label>
                    {" "}
                    any representation, information or statement made or deemed
                    to be made by an Obligor in this Agreement, Application,
                    Disclosure Document, the other Finance Documents or any
                    other document delivered by or on behalf of any Obligor
                    under or in connection with any document supplementing this
                    Agreement is true and correct;
                  </label>
                  <br />
                  <span>17.1.7 </span>{" "}
                  <label>
                    {" "}
                    that the direct-debit authorisation/application forms
                    submitted have valid and actionable signatures;
                  </label>
                  <br />
                  <span>17.1.8 </span>{" "}
                  <label>
                    {" "}
                    that the actions, communications, agreements, requests and
                    instructions by the Authorised Signatories are deemed good
                    and binding on the Borrower at all material times;
                  </label>
                  <br />
                  <span>17.1.9 </span>{" "}
                  <label>
                    {" "}
                    no litigation is current or pending or, so far as the
                    Obligor is aware, threatened (i) to restrain the entry into,
                    exercise of the rights under or performance or enforcement
                    of or compliance with obligations under this Agreement
                    and/or any of the Finance Documents to which it is a party,
                    or (ii) which has or could have a material adverse effect on
                    such Obligor;
                  </label>
                  <br />
                  <span>17.1.10 </span>{" "}
                  <label>
                    {" "}
                    it is not bankrupt and/or insolvent and no steps have been
                    taken by the Obligor nor have any legal proceedings been
                    started for the appointment of a receiver, trustee, nominee,
                    supervisor, judicial manager or similar officer of the
                    assets of the Obligor;
                  </label>
                  <br />
                  <span>17.1.11 </span>{" "}
                  <label>
                    {" "}
                    it has not entered into or proposed to enter into any
                    arrangement, merger, demerger, corporate reconstruction or
                    composition (voluntary or otherwise) with any of its
                    creditors and no declaration has been made by any court or
                    authority in respect of a moratorium on the payment of
                    indebtedness or other suspension of payments generally;
                  </label>
                  <br />
                  <span>17.1.12 </span>{" "}
                  <label>
                    {" "}
                    no proposal has been made by it to be placed under judicial
                    management and no resolution has been passed or application
                    made for it to be placed under judicial management;
                  </label>
                  <br />
                  <span>17.1.13 </span>{" "}
                  <label>
                    {" "}
                    it has conducted its businesses in compliance with
                    applicable anti-corruption laws and has instituted and
                    maintained policies and procedures designed to promote and
                    achieve compliance with such laws;
                  </label>
                  <br />
                  <span>17.1.14 </span>{" "}
                  <label>
                    {" "}
                    neither it nor any director, officer, employee or Affiliate,
                    is currently a designated target of, or is otherwise a
                    subject of, Sanctions;
                  </label>
                  <br />
                  <span>17.1.15 </span>{" "}
                  <label>
                    {" "}
                    it has duly obtained its directors’, shareholders’,
                    officers’, guarantors’ and/or such other relevant persons’
                    consent and authority as may be required under the
                    applicable laws, including but not limited to the Personal
                    Data Protection Act 2012 (No. 26 of 2012), for the Lender
                    amongst others) to the extent permitted by law: <br />
                    <span>(a) </span>{" "}
                    <label>
                      {" "}
                      to be provided information (inclusive of relevant personal
                      information of the said directors, shareholders, officers,
                      guarantors and/or relevant persons) as may be required by
                      the Lender for use in accordance with relevant terms and
                      conditions and for the purpose of the grant, continued
                      maintenance and enforcement of the Facility; and
                    </label>
                    <br />
                    <span>(b) </span>{" "}
                    <label>
                      {" "}
                      to carry out the necessary reference checks including but
                      not limited to credit reference/reporting checks to
                      further ascertain the status of the Borrower and its
                      subsidiaries, directors, shareholders, officers,
                      guarantors and/or such other relevant persons,
                    </label>
                    <br />{" "}
                    <label>
                      at Lender’ sole discretion, without further reference to
                      the Obligor, its subsidiaries, the directors, its
                      shareholders, officers, guarantors and/or relevant
                      persons;
                    </label>
                    <br />
                  </label>
                  <span>17.1.16 </span>{" "}
                  <label>
                    {" "}
                    That no other person other than the Borrower has or will
                    have any interest in the Facility and that all monies which
                    will be paid to the Lender, all collateral, Security and
                    Guarantee in favour of the Lender shall come from a lawful
                    source of activity and not in contravention with AMLA;
                  </label>{" "}
                  <br />
                  <span>17.1.17 </span>{" "}
                  <label>
                    {" "}
                    That there are no defects, structural or otherwise, in or
                    affecting theSecurity;
                  </label>
                  <br />
                  <span>17.1.18 </span>{" "}
                  <label>
                    {" "}
                    In the event where the Guarantor or Security Provider is an
                    individual, that – <br />
                    <span>(a) </span>{" "}
                    <label>
                      {" "}
                      he or she is not a minor and has not been declared to be
                      incompetent or of an unsound mind, and is of sound mind;
                      and
                    </label>{" "}
                    <br />
                    <span>(b) </span>{" "}
                    <label>
                      {" "}
                      he or she is not an undischarged bankrupt and that there
                      is no available act of bankruptcy subsisting in respect of
                      him / her; <br />
                    </label>
                  </label>
                  <span>17.1.19 </span>{" "}
                  <label>
                    {" "}
                    None of the representations and warranties in this Clause 17
                    (Obligor’s Representations and Warranties) omit any matter
                    the omission of which makes any of such representations and
                    warranties misleading;
                  </label>
                  <br />
                  <span>17.1.20 </span>{" "}
                  <label>
                    {" "}
                    All tax returns and reports of the Obligors required by law
                    to be filed have been duly filed and all taxes, obligations,
                    fees and other governmental charges upon Obligors or their
                    respective properties income or assets, which are due and
                    payable or to be withheld, have been paid or withheld, other
                    than those presently payable without penalty or interest;
                  </label>
                  <br />
                  <span>17.1.21 </span>{" "}
                  <label>
                    {" "}
                    None of the Obligors or any of their respective property
                    enjoys any right of immunity from set-off, suit or execution
                    with respect to their respective assets or their respective
                    obligations under any Finance Document;
                  </label>
                  <br />
                  <span>17.1.22 </span>{" "}
                  <label>
                    {" "}
                    To the best of its knowledge and belief, after due inquiry,
                    none of the Obligors is in violation of any statute or
                    regulation of any authority in connection with the conduct
                    of its respective business or ownership of its respective
                    property;
                  </label>
                  <br />
                  <span>17.1.23 </span>{" "}
                  <label>
                    {" "}
                    Each obligor has complied with all applicable environmental
                    laws and are not aware of any basis for any environmental
                    claim against it;
                  </label>
                  <br />
                  <span>17.1.24 </span>{" "}
                  <label>
                    {" "}
                    The choice of governing law of each Finance Document will be
                    recognised and enforced respectively in each obligor &apos;s
                    jurisdiction of incorporation (if applicable), domicile or
                    establishment (if applicable);
                  </label>
                  <br />
                  <span>17.1.25 </span>{" "}
                  <label>
                    {" "}
                    Any judgment obtained in the courts which the parties to a
                    Finance Document have conferred jurisdiction on to settle
                    disputes in relation to that Finance Document will be
                    recognised and enforced in each Obligor &apos;s jurisdiction
                    of incorporation or domicile (as applicable);
                  </label>
                  <br />
                  <span>17.1.26 </span>{" "}
                  <label>
                    {" "}
                    The obligations of each Obligor under the Finance Documents
                    are direct, unconditional and unsubordinated and rank at
                    least pari passu with all its other present and future
                    unsecured and unsubordinated obligations (except for
                    obligations mandatorily preferred by law); and
                  </label>
                  <br />
                  <span>17.1.27 </span>{" "}
                  <label>
                    {" "}
                    The Financial Statements most recently delivered to Lender
                    save, where relevant, for management accounts or statements,
                    have been prepared in accordance with the relevant GAAP,
                    consistently applied; and fairly represent/ give a true and
                    fair view of the financial condition and operations the
                    Obligors and the Borrower’s group members (as the case may
                    be) as at the date to which they were drawn up and there has
                    been in our opinion no material adverse change in Obligor’s
                    business, operations, assets or financial condition (or the
                    business, operations, assets or consolidated financial
                    condition of Borrower’s group members, if you are a
                    corporation) since the date to which those accounts were
                    drawn up.
                  </label>
                  <br />
                </label>
              </li>
              <li>
                <h3>OBLIGORS’ COVENANTS</h3>
                <span>18.1 </span>
                <label>
                  In the event Lender requires that any documents be delivered
                  by the Obligors to Lender via electronic means the Obligors
                  covenants and represents that such documents so delivered are
                  genuine, unaltered, untampered, unmodified and in the same
                  form and substance with the original physical or paper copy.
                </label>

                <p>
                  <br /> <span>18.2 </span> Any documents, notice, application,
                  requests or communication sent by the Obligors to Lender is
                  deemed to be binding on the Obligors if the documents, notice,
                  application, requests or communication is sent by way of
                  e-mail from any e-mail address of the respective Obligor as
                  stated herein.
                </p>

                <p>
                  <span>18.3 </span> Lender is entitled to assume and act upon
                  any documents, instructions, approval and/or notice sent by
                  the Obligors by way of electronic mail of which has been
                  identified with the Obligors or as provided by this Agreement.
                </p>

                <p>
                  <span>18.4 </span> The Obligors covenant that its payment
                  obligations under this Agreement and/or the Finance Documents
                  shall rank above and take precedence over claims of all of its
                  other unsecured and unsubordinated creditors, except for
                  obligations mandatorily preferred by law.
                </p>

                <p>
                  <span>18.5 </span> The Obligors shall ensure that, any
                  loans/indebtedness (of any kind) availed or to be availed by
                  the Obligors from any of its shareholders, related parties,
                  relatives or Affiliates, shall at all times, be subordinated
                  to the Facility and all obligation of the Obligors to the
                  Lender under the Finance Documents.
                </p>

                <p>
                  <span>18.6 </span> No Obligors shall declare, make or pay any
                  dividends, or distributions until all obligations under the
                  Facility have been repaid.
                </p>

                <p>
                  <span>18.7 </span> The Borrower shall: <br />
                  <br />{" "}
                  <label>
                    <span>18.7.1 </span> At the request of Lender furnish any
                    information about the financial condition or state of
                    affairs of the Obligors or security given by the Obligors;
                  </label>{" "}
                  <br />
                  <label>
                    <span>18.7.2 </span> Keep proper books of account which give
                    a true and fair view of the financial condition and state of
                    affairs of it; and
                  </label>{" "}
                  <br />
                  <label>
                    <span>18.7.3 </span> Ensure that the accounts furnished to
                    Lender are prepared in accordance with the requirements of
                    the Companies Act (where applicable) and all relevant
                    accounting standards and practices generally accepted in
                    Singapore;
                  </label>{" "}
                  <br />
                </p>

                <p>
                  <span>18.8 </span> The Obligors must do everything necessary
                  to preserve the corporate existence / business existence of
                  itself and each Security Provider in good standing and its
                  right to carry on operation including but not limited to:{" "}
                  <br />
                  <br />{" "}
                  <label>
                    <span>18.8.1 </span> Not transferring the jurisdiction of
                    registration;
                  </label>{" "}
                  <br />
                  <label>
                    <span>18.8.2 </span> Not making any application or passing
                    any resolution for windingup (where applicable);
                  </label>{" "}
                  <br />
                  <label>
                    <span>18.8.3 </span> Not making any application to end or
                    dissolve the business;
                  </label>{" "}
                  <br />
                  <label>
                    <span>18.8.4 </span> Not entering into or effecting any
                    scheme of arrangement or merger or consolidation with any
                    other person or persons; and
                  </label>{" "}
                  <br />
                  <label>
                    <span>18.8.5 </span> Not entering into or effecting any
                    other scheme under which it or any Security Provider ceases
                    to exist or under which the assets or liabilities or both of
                    itself or any Security Provider are vested in or assumed by
                    any other person.
                  </label>{" "}
                  <br />
                </p>

                <p>
                  <span>18.9 </span> The Obligors shall promptly (and in any
                  event not more than{" "}
                  <span className="termsUnderline">seven (7) days</span>
                  from the date of notification, demand or request from Lender)
                  do all such acts or execute all such documents (including
                  assignments, transfers, mortgages, charges, notices and
                  instructions) as Lender, may reasonably specify (and in such
                  form as Lender, may reasonably require in favour of the Lender
                  or its nominee(s)): <br />
                  <br /> <span>18.9.1 </span>{" "}
                  <label>
                    {" "}
                    to create Security or perfect the Security created or
                    intended to be created under or evidenced by the Security
                    Documents (which may include the execution of a mortgage,
                    charge, assignment or other Security over all or any of the
                    assets which are, or are intended to be, the subject of the
                    Security) or for the exercise of any rights, powers and
                    remedies of the Lender provided by or pursuant to the
                    Finance Documents or by law; and/or
                  </label>{" "}
                  <br />
                  <span>18.9.2 </span>{" "}
                  <label>
                    {" "}
                    to confer on the Lender security over any property and
                    assets of that Obligor located in any jurisdiction
                    equivalent or similar to the Security intended to be
                    conferred by or pursuant to the Security Documents; and/or
                  </label>{" "}
                  <br />
                  <span>18.9.3 </span>{" "}
                  <label>
                    {" "}
                    to deliver up to Lender all title documents, perfection
                    documents relevant to any Security or mortgaged property and
                    all duly executed Security Documents and other supporting
                    documents including the deeds and documents of discharge or
                    release of the existing mortgages, charges, caveats or
                    encumbrances over the Security or mortgaged property; and/or
                  </label>{" "}
                  <br />
                  <span>18.9.4 </span>{" "}
                  <label>
                    to assist or cause to prepare, execute, register and lodge
                    caveats in the interest of Lender or its appointed
                    custodian, agent or trustee (if applicable) with the land
                    authority; and/or
                  </label>{" "}
                  <br />
                  <span>18.9.5 </span>{" "}
                  <label>
                    {" "}
                    to facilitate the realisation of the assets which are, or
                    are intended to be, the subject of the Security.
                  </label>{" "}
                  <br />
                </p>

                <p>
                  <span>18.10 </span> The Obligors shall take all such action as
                  is available to it (including making all filings and
                  registrations) as may be necessary for the purpose of the
                  creation, perfection, protection or maintenance of any
                  Security conferred or intended to be conferred on the Lender
                  by or pursuant to the FinanceDocuments.
                </p>

                <p>
                  <span>18.11 </span> Any Security (including the Security in
                  the form of mortgage or charge) given by the Obligors shall be
                  a cumulative and continuing Security to Lender regardless of
                  any intermediate payment or discharge of the whole or any part
                  of the Line Facility and will not be prejudiced or affected by
                  any act, omission or circumstances which, but for this clause
                  may affect or diminish its effectiveness.
                </p>

                <p>
                  <span>18.12 </span> Any Security (including the Security in
                  the form of mortgage or charge) shall be in addition to, and
                  is not in any way prejudiced by any rights that Lender may
                  have, at law or pursuant to this Agreement, in respect of the
                  Facility, including any rights arising under any other
                  Security.
                </p>

                <p>
                  <span>18.13 </span> Where Security is a mortgage property,
                  that all rent, property tax, service and conservancy charges,
                  maintenance fees, sinking fund contributions and/or such other
                  fees charges and contributions payable in respect of the
                  mortgaged property have been paid by the Obligors. Further,
                  where applicable, all consents, approvals and authorisations
                  required in connection with the Security has been obtained by
                  theObligors.
                </p>

                <p>
                  <span>18.14 </span> The Obligors shall bear all costs
                  (including legal costs), fees, expenses and other charges,
                  including stamp duties, registration, requisition and search
                  fees connected with: <br />
                  <br /> <span>18.14.1 </span>{" "}
                  <label>
                    the preparation, execution and registration of the Security,
                    the maintenance, enforcement and discharge thereof; and
                  </label>{" "}
                  <br />
                  <span>18.14.2 </span>{" "}
                  <label>
                    {" "}
                    the preparation, completion and registration of any caveats
                    against Security; and
                  </label>{" "}
                  <br />
                  <span>18.14.3 </span>{" "}
                  <label>
                    all other documents of whatever nature that Lender may
                    require in relation to the Security or the perfection of the
                    security conferred, at such time as Lender deems
                    appropriate.
                  </label>{" "}
                  <br />
                </p>

                <p>
                  <span>18.15 </span> The Obligors must obtain, maintain and
                  comply with, and shall procure that each Obligor obtains,
                  maintains and complies with, any authorisation required under
                  any law to enable it perform its obligations under, or for the
                  validity or enforceability of, any Finance Document.
                </p>

                <p>
                  <span>18.16 </span> Borrower must comply in all respects with
                  all laws to which you are subject where failure to do so might
                  in Lender opinion have a material adverse effect on Borrower’s
                  business, assets, financial condition or prospects or its
                  ability to perform your obligations under the finance
                  documents or the business, assets, financial condition or
                  prospects of group members (taken as a whole) or Affiliate
                  ability to perform its obligations under the Finance
                  Documents,).
                </p>

                <p>
                  <span>18.17 </span> Obligor’s shall ensure that their
                  obligations and liabilities under each Finance Document will
                  at all times rank (except in respect of statutory preferential
                  debts) at least pari passu with all present and future
                  unsecured indebtedness.
                </p>

                <p>
                  <span>18.18 </span> The Obligors will not (each such
                  transaction being a “Quasi-Security”): <br />
                  <br /> <span>18.18.1 </span>{" "}
                  <label>
                    {" "}
                    create or permit to subsist any Security interest over any
                    of its assets;
                  </label>{" "}
                  <br />
                  <span>18.18.2 </span>{" "}
                  <label>
                    {" "}
                    sell, transfer or otherwise dispose of any of its assets on
                    terms by which they are or may be leased to or re-acquired
                    by it or its holding company (where applicable);
                  </label>{" "}
                  <br />
                  <span>18.18.3 </span>{" "}
                  <label>
                    {" "}
                    sell, transfer or otherwise dispose of any of the Security
                    on recourse terms;
                  </label>{" "}
                  <br />
                  <span>18.18.4 </span>{" "}
                  <label>
                    {" "}
                    enter into any arrangement under which money or the benefit
                    of a bank or other account may be applied, set-off or made
                    subject to a combination of accounts; or
                  </label>{" "}
                  <br />
                  <span>18.18.5 </span>{" "}
                  <label>
                    enter into any preferential arrangement having a similar
                    effect;
                  </label>{" "}
                  <br />
                  <span>18.18.6 </span>{" "}
                  <label>
                    {" "}
                    in the circumstances where the arrangement or transaction is
                    entered into primarily as a method of raising Indebtedness
                    or financing the acquisition of an asset.
                  </label>{" "}
                  <br />
                  <span>18.18.7 </span>{" "}
                  <label>
                    {" "}
                    Clauses 18.18.1 to 18.18.6 above do not apply to any: <br />
                    <span>(a) </span>{" "}
                    <label>
                      {" "}
                      netting or set-off arrangement entered into by any holding
                      company or any Obligor in the ordinary course of trade
                      arrangements for the purpose of netting debit and credit
                      balances;
                    </label>
                    <br />
                    <span>(b) </span>{" "}
                    <label>
                      lien arising by operation of law and in the ordinary
                      course of trading or retention of title arrangement in the
                      ordinary course of trading on standard terms and
                      conditions of any debtors;
                    </label>
                    <br />
                    <span>(c) </span>{" "}
                    <label>
                      {" "}
                      Security interest or Quasi-Security over goods and/or
                      documents of title to goods arising in the ordinary course
                      of letter of credit transactions in the ordinary course of
                      trade;
                    </label>
                    <br />
                    <span>(d) </span>{" "}
                    <label>
                      {" "}
                      Security interest or Quasi-Security as advised by an
                      Obligor and approved by Lender except to the extent that
                      the principal amount secured by such Security interest or
                      Quasi-Security exceeds the relevant maximum principal
                      amount as specified by Lender in respect of such Security
                      Interest or Quasi-Security; and
                    </label>
                    <br />
                    <span>(e) </span>{" "}
                    <label>
                      {" "}
                      Security interest or Quasi-Security created with the prior
                      written consent of Lender provided that the principal
                      amount is not increased at any time.
                    </label>
                    <br />
                  </label>
                </p>

                <p>
                  <span>18.19 </span> The Obligors will not dispose of all or
                  any part of its assets or make any acquisition or investment
                  except where made in the ordinary course of trading and, in
                  relation to a disposal of assets only, of assets in exchange
                  for other assets comparable or superior as to type and value.
                </p>

                <p>
                  <span>18.20 </span> Where the Obligor is a corporation, it
                  must not undertake or to permit any arrangement or
                  reconstruction of its present constitution nor effect any
                  changes to its constitutional documents.
                </p>

                <p>
                  <span>18.21 </span> In the event the payment and repayment of
                  the Facility or any part thereof by the Borrower and/or any
                  Security Provider is found to be from an unlawful source of
                  activity and not a lawful activity as defined under the AMLA
                  at any time and from time to time upon the discharge and
                  release of the Borrower’s obligations hereunder by the Lender,
                  each Obligor hereby agrees and acknowledges that: <br />
                  <br />{" "}
                  <label>
                    <span>18.21.1 </span> the release and the discharge of the
                    Borrower’s obligations hereunder shall be automatically
                    deemed to be invalid from the date it is established that
                    the source of payment and repayment of the Facility or any
                    part thereof falls within the ambit of the AMLA and each
                    Obligor shall continue to be liable to the Lender under the
                    terms of this Agreement and/or any of the Finance Documents
                    to which it is a party notwithstanding any document(s)
                    issued and/or executed by the Lender to discharge and
                    release the Obligors; and
                  </label>
                  <br />
                  <label>
                    <span>18.21.2 </span> it shall indemnity and keep the Lender
                    fully indemnified and shall cause the Security Provider to
                    indemnify the Lender (on full indemnity basis) for any
                    losses, damages, costs, fees and charges incurred by the
                    Lender as a result of contravention by any of the Obligors
                    of the provisions of the AMLA.
                  </label>
                  <br />
                </p>

                <p>
                  <span>18.22 </span> The Obligors shall notify Lender promptly
                  upon becoming aware of the occurrence of the following: any
                  representation, warranty, undertaking, information or
                  statement made or deemed to be made, or information provided,
                  by any Obligor to Lender is reasonably likely to be incorrect
                  or misleading; any disposal of all or any part of your or any
                  of Borrower group member &apos;s assets, except where such
                  disposal of assets is: (i) made in the ordinary course of
                  trading; or (ii) in exchange for other assets comparable or
                  superior as to type and value.
                </p>

                <p>
                  <span>18.23 </span> The Obligors shall notify Lender promptly
                  upon initiation of any amalgamation, demerger, merger or
                  corporate reconstruction by any Obligor or any member of the
                  Borrower’s group.
                </p>

                <p>
                  <span>18.24 </span> The Obligors shall notify Lender promptly
                  upon initiation of any acquisition or investment in a company
                  by any Obligor or any members of Borrower’s group, except
                  where such acquisition or investment is made in the ordinary
                  course of trading members.
                </p>

                <p>
                  <span>18.25 </span> The Obligors shall notify Lender promptly
                  upon any Obligor having any substantial change which will have
                  an effect on the general nature of its business or that of any
                  of its Affiliates of the Borrower from that carried on at the
                  date of this Agreement.
                </p>

                <p>
                  <span>18.26 </span> The Obligors shall notify Lender promptly
                  upon occurrence of any change in Obligor’s directors or
                  beneficial owners.
                </p>

                <p>
                  <span>18.27 </span> The Obligors shall notify Lender promptly
                  upon any event or series of events occurs (including the
                  commencement of any legal proceedings or other analogous
                  process in any jurisdiction) which has or is reasonably likely
                  to have a Material Adverse Effect on: (i) any Obligor’s
                  business, operations, assets, financial condition, results or
                  prospects or those of Obligor’s group as a whole; or (ii) the
                  validity, binding effect or enforceability of any Finance
                  Document.
                </p>

                <p>
                  <span>18.28 </span> Each Obligors shall ensure that:
                  <br />
                  <span>(a) </span>{" "}
                  <label>
                    {" "}
                    ensure that Lender receive its Financial Statements as soon
                    as they become available;
                  </label>{" "}
                  <br />
                  <span>(b) </span>{" "}
                  <label>
                    provide Lender with accurate and up-to-date information
                    necessary to enable Lender to comply with any applicable
                    law, &apos;know your customer&apos; or similar
                    identification procedures as we may request from time to
                    time and notify us immediately of any changes;
                  </label>{" "}
                  <br />
                  <span>(c) </span>{" "}
                  <label>
                    {" "}
                    provide Lender with details of any litigation, arbitration
                    or other proceedings, pending or threatened, against it or
                    its group;
                  </label>{" "}
                  <br />
                  <span>(d) </span>{" "}
                  <label>
                    {" "}
                    upon Lender request, ensure that Lender receive
                    monthly/quarterly stocks/work in progress/debtors lists
                    where stocks, work in progress or debts are charged or
                    secured to us;
                  </label>{" "}
                  <br />
                  <span>(e) </span>{" "}
                  <label>
                    {" "}
                    it is a corporation, ensure that Lender receive any further
                    information we may reasonably request from time to time in
                    writing regarding you or your group.
                  </label>{" "}
                  <br />
                </p>

                <p>
                  <span>18.29 </span> Each Obligors shall procure that no
                  substantial change is made which will have an effect on the
                  general nature of its business or that of its group from that
                  carried on at the date of this Agreement.
                </p>

                <p>
                  <span>18.30 </span> No Obligors shall change its present
                  status or constitution nor effect any change to your
                  constitutional documents without Lender prior written consent.
                  Each Obligor must immediately inform Lender of any change of
                  your directors or beneficial owners or amendment to your
                  constitutional documents.
                </p>
              </li>
              <li>
                <h3>EXCLUSION OF LIABILITY</h3>
                <span>19.1 </span>
                <label> Lender is not liable to or responsible for:</label>{" "}
                <br />
                <br /> <span>19.1.1 </span>{" "}
                <label>
                  the inadequacy, inaccuracy or incompleteness of any
                  information (whether oral or written) supplied by the
                  Borrower, the Guarantors or any other person in or in
                  connection with this Agreement or any Finance Document or the
                  transactions contemplated in the Finance Documents or any
                  other agreement, arrangement or document entered into, made or
                  executed in anticipation of, under or in connection with any
                  Finance Document;
                </label>{" "}
                <br />
                <span>19.1.2 </span>{" "}
                <label>
                  {" "}
                  the legality, validity, effectiveness, adequacy or
                  enforceability of any Finance Document or any other agreement,
                  arrangement or document entered into, made or executed in
                  anticipation of, under or in connection with any Finance
                  Document;
                </label>{" "}
                <br />
                <span>19.1.3 </span>{" "}
                <label>
                  {" "}
                  Lender shall not be bound to enquire as to whether or not any
                  Event of Default has occurred or anticipated to occur;
                </label>{" "}
                <br />
                <span>19.1.4 </span>{" "}
                <label>
                  {" "}
                  any damages, costs or losses to any person, any diminution in
                  value or any liability whatsoever due to claims of whatsoever
                  nature which may include claims of negligence, arising as a
                  result of: <br />
                  <label>
                    (a) Any act, event or circumstance not within its control;
                    or
                  </label>
                  <label>
                    (b) The general risks of investment in, or the holding of
                    assets in, any jurisdiction.
                  </label>
                </label>{" "}
                <br />
                <p>
                  <span>19.1.5 </span> any damages, costs, losses, diminution in
                  value or liability arising as a result of nationalisation,
                  expropriation or other governmental actions; any regulation,
                  currency restriction, devaluation or fluctuation; market
                  conditions affecting the execution or settlement of
                  transactions or the value of assets; breakdown, failure or
                  malfunction of any third party transport, telecommunications,
                  computer services or systems; natural disasters or acts of
                  God; war, terrorism, insurrection or revolution; or strikes or
                  industrial action;
                </p>
                <span>19.1.6 </span>
                <label>
                  {" "}
                  any delay (or any related consequences thereto) in the
                  disbursement of the Facility Amount to the Borrower if Lender
                  has taken all necessary steps as soon as reasonably
                  practicable to effect such disbursement.
                </label>
              </li>
              <li>
                <h3>OPERATION OF LENDER IN THE EVENT OF WINDING UP</h3>
                <p>
                  In the event that Lender is undergoing winding up proceedings
                  or is insolvent, an appointed representative approved by the
                  regulators and/or a court appointed representative will take
                  over Lender’ role in terms of servicing of this Agreement and
                  Lender shall assign its rights under this Agreement to the
                  said appointed representative.
                </p>
              </li>
              <li>
                <h3>INDEMNITY</h3>
                <label>
                  <span>21.1 </span> The Borrower and Guarantors shall at all
                  times fully indemnify and keep the Lender including any of its
                  agents, principal, employees or representatives indemnified
                  against all and any actions, investigations, proceeding,
                  claim, expense, loss, damage or liability which the Lender may
                  incur as a consequence of or arising from or connected to the
                  Borrower’s control, possession or use of the Facility and the
                  Finance Documents, including but not limited to any damage or
                  injury to persons or property and the costs and liabilities
                  arising from such claims.
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  <span>21.2 </span> The Borrower and Guarantors shall at all
                  times fully indemnify and keep the Lender including any of its
                  agents, principal, employees or representatives indemnified
                  against all and any actions, investigations, proceeding,
                  claim, expense, loss, damage or liability (as to the amount of
                  which a certificate of Lender, shall in the absence of
                  manifest error, be conclusive and binding upon the Borrower
                  and Guarantors) which Lender may incur as a consequence of any
                  Event of Default or otherwise in connection with this
                  Agreement or other related documents unless such expense,
                  loss, damage or liability is attributable to the wilful
                  default, gross negligence or fraud of Lender.
                </label>{" "}
                <br />
                <br />{" "}
                <label>
                  <span>21.3 </span> Without prejudice to its generality, the
                  foregoing indemnity shall extend to any expenses, fees
                  (including legal fees on solicitors and client basis) or other
                  sums whatsoever paid or payable in connection with the
                  enforcement of any of the rights of the Lender under this
                  Agreement and the FinanceDocuments.
                </label>{" "}
                <br />
              </li>
              <li>
                <h3>EXPENSES</h3>
                <p>
                  The Borrower shall on demand pay to Lender and bear from time
                  to time all whatsoever, costs, fees and charges, expenses,
                  taxes and other monies in any way connected with or arising
                  out of the Facility, Finance Document from time to time
                  including but not limited to all costs, fees and charges,
                  expenses, taxes and other monies on full indemnity basis
                  whatsoever including but not limited to: <br />
                  <br />{" "}
                  <label>
                    <span>22.1.1 </span> legal and out-of-pocket expenses
                    incurred by Lender in connection with the negotiation,
                    preparation or completion of this Agreement and any related
                    documents;
                  </label>{" "}
                  <br />
                  <label>
                    <span>22.1.2 </span> the charges of the valuers, appraisers,
                    estate agents and auctioneers;
                  </label>{" "}
                  <br />
                  <label>
                    <span>22.1.3 </span> any expenses covenanted to be paid by
                    the Borrower under this Agreement which are paid by Lender
                    on behalf of the Borrower and form part of the Indebtedness
                    herein;
                  </label>{" "}
                  <br />
                  <label>
                    <span>22.1.4 </span> quit rent, assessment, rates, taxes,
                    drainage charges, sewerage charges, insurance
                    contributions/premiums, sinking fund payments and other
                    outgoings, service charges, maintenance fee, charges and
                    payments whatsoever that may be payable in respect of any
                    property which is the subject matter of any Finance Document
                    incurred or expended by Lender in the negotiation,
                    preparation, execution, completion, stamping, registration
                    and/or perfection and/or discharge and/or release of any
                    security and/or in the collection of any moneys due or
                    becoming due under the Facility and/or in enforcing and/or
                    preserving any of the security and/or any of the rights
                    under the Security Documents and/or in connection with or
                    arising out of any variation or review of the Facility by
                    Lender irrespective of whether or not the Facility is
                    utilised, suspended or cancelled;and
                  </label>{" "}
                  <br />
                  <label>
                    <span>22.1.5 </span> all costs, charges, expenses, taxes and
                    moneys referred to in this Agreement and any of the
                    provisions of the Finance Document or otherwise howsoever
                    incurred hereunder by Lender including any expenditure
                    incurred in the preservation and/or enforcement of this
                    Agreement and the other Security Documents and any documents
                    related thereto or in the giving of any notice or in the
                    making of any demand, under or pursuant to or in respect of
                    this Agreement.
                  </label>{" "}
                  <br />
                </p>
                <span>22.2 </span>
                <label>
                  {" "}
                  The Borrower shall pay all stamp, documentary and other
                  similar duties and taxes to which this Agreement, Finance
                  Document and its amendment or supplemental agreements or any
                  related documents may be subject and shall fully indemnify the
                  Lender from and against any expense, damage, loss or liability
                  which any of them may incur as a result of any delay or
                  omission by the Borrower to pay such duties.
                </label>{" "}
                <br />
                <br /> <span>22.3 </span>
                <label>
                  {" "}
                  The foregoing indemnities shall constitute obligations of the
                  Borrower and the Guarantors, separate and independent from its
                  other obligations under this Agreement, and shall give rise to
                  separate and independent causes of action against the Borrower
                  and the Guarantors.
                </label>
              </li>
              <li>
                <h3>BENEFIT OF AGREEMENT</h3>
                <span>23.1 </span>
                <label>
                  {" "}
                  Lender shall be entitled without giving notice to the Borrower
                  and the Guarantors to facilitate the assign of the rights of
                  itself under this Agreement or any part thereof and/or
                  transfer the obligations of itself under this Agreement or any
                  part thereof: <br />
                  <br /> <span>23.1.1 </span>{" "}
                  <label>
                    {" "}
                    for this purpose, may disclose to a potential assignee or
                    transferee or any other person who derives or may derive
                    rights or obligations under or by reference to this
                    Agreement such information about the Borrower, Guarantors
                    and/or Security Providers as shall have been made available
                    to Lender;
                  </label>{" "}
                  <br />
                  <span>23.1.2 </span>{" "}
                  <label>
                    {" "}
                    where Lender transfers its obligations or any part thereof,
                    the Borrower, Guarantors and/or Security Providers shall
                    execute such documents as are reasonably necessary to
                    release Lender to the extent of the transfer and join the
                    transferee as a party hereto; and
                  </label>
                  <br />
                  <span>23.1.3 </span>{" "}
                  <label>
                    {" "}
                    where Lender transfers all its obligations and rights
                    hereunder the Borrower, Guarantors and/or Security Providers
                    shall thereafter deal solely with the transferee with
                    respect to payments, notices and other matters relating to
                    the administration of this Agreement.
                  </label>
                  <br />
                </label>
                <br /> <span>23.2 </span>
                <label>
                  {" "}
                  All costs and expenses incurred by Lender and/or the
                  assignee/transferee pursuant to or incidental to such
                  assignment/transfer shall be payable by the Borrower,
                  Guarantors and/or the Security Providers.
                </label>{" "}
                <br />
                <br /> <span>23.3 </span>
                <label>
                  {" "}
                  For the avoidance of doubt, no such assignments, transfer,
                  sale or novation shall invalidate, remove, distinguish or
                  affect any obligation of the Borrower, Guarantors and/or the
                  Security Providers under this Agreement,
                </label>
              </li>
              <li>
                <h3>ASSIGNMENT</h3>
                <p>
                  The Borrower and Guarantors shall not assign, transfer or
                  novate any of its rights, obligations and/or benefits of this
                  Agreement to any party or third party without the prior
                  written consent of Lender. The Lender may assign, transfer or
                  novate any of its rights, obligations and/or benefits of this
                  Agreement and any other Finance Document to any party or third
                  party without prior notice or consent of any of the Obligor.
                </p>
              </li>
              <li>
                <h3>CERTIFICATE OF INDEBTEDNESS</h3>
                <p>
                  For all purposes, including legal proceedings relating to this
                  Agreement and the Facility, a statement of account in writing
                  stating the Indebtedness or the total amount due and payable
                  to the Lender which may or may not have any indication as to
                  how such amount has been calculated which is certified and
                  signed as being correct by an officer of Lender shall, in the
                  absence of manifest error, be conclusive evidence or proof
                  that such amount appearing therein is due and owing and
                  payable by the Borrower to the Lender.
                </p>
              </li>
              <li>
                <h3>RELEASE AND INDULGENCE</h3>
                <p>
                  Any liability to any Party hereto may in whole or in part be
                  released, compounded or compromised or be given time or
                  indulgence by that Party in that Party’s absolute discretion
                  as regards any other Party hereto under such liability without
                  in any way prejudicing or affecting the first Party’s rights
                  against any other Party or Parties hereto under the same or a
                  like liability whether joint or several or otherwise.
                </p>
              </li>
              <li>
                <h3>FURTHER ASSURANCE</h3>
                <p>
                  The Borrower shall from time to time and at any time, whether
                  before or after this Agreement or any of the Security
                  Documents shall have become enforceable, execute, procure and
                  do or cause to be done all such execution, transfer,
                  assignments, assurances, charges, debentures, instruments,
                  documents, acts and things as may reasonably be required for
                  this Agreement or for perfecting the security intended to be
                  hereby constituted and for facilitating the realisation of the
                  any property charged or to be charged to and the exercise by
                  it of all the powers, authorities and discretion which Lender
                  may think expedient. For such purposes, a certificate in
                  writing signed by or on behalf of Lender to the effect that
                  any particular transfer, execution, assignment, assurance,
                  charge, debenture, instrument, document, act or thing required
                  by it is reasonably required by it shall be conclusive
                  evidence of the fact.
                </p>
              </li>
              <li>
                <h3>AUTHORISATION ON DISCLOSURE OF INFORMATION</h3>
                <p>
                  <span>28.1 </span> Without limiting the subsequent provisions
                  of this Clause, Lender may at any time, disclose to any person
                  (including its Affiliates, service providers and consultants)
                  who may, in Lender’ absolute discretion, require such
                  information or access thereof any documents or records of, or
                  information about the Facility, Finance Document or assets or
                  affairs of the Borrower and the Guarantors whether or not
                  confidential and whether or not the disclosure would be in
                  breach of any law or of any duty owed to the Borrower and the
                  Guarantor provided that in respect of documents, records or
                  information which the Borrower and the Guarantor has informed
                  the Lender to be confidential, the person receiving such
                  information from Lender may be required to undertake to
                  maintain the confidentiality of documents, records or
                  information received.
                </p>

                <p>
                  <span>28.2 </span> Each Obligor hereby irrevocably consents
                  that Lender may disclose any documents or records of, or
                  information about this Agreement and/or the Facility or the
                  assets or affairs of the Obligors, whether or not confidential
                  for the purpose of: <br />
                  <br />{" "}
                  <label>
                    <span>28.2.1 </span> developing and providing financing
                    facilities, products or services (whether made available by
                    us or through us), including but not limited to: <br />
                    <span>(a) </span>{" "}
                    <label>
                      {" "}
                      its advisors, service providers, consultants, executing
                      investments, banking, commercial or other transactions and
                      clearing or reporting on these transactions;
                    </label>{" "}
                    <br />
                    <span>(b) </span>{" "}
                    <label>
                      {" "}
                      carrying out research, planning and statistical analysis;
                      or
                    </label>{" "}
                    <br />
                    <span>(c) </span>{" "}
                    <label>
                      {" "}
                      analytics for the purposes of developing or improving our
                      products, services, security, service quality, and
                      advertising strategies;
                    </label>
                  </label>{" "}
                  <br />
                  <span> 28.2.2</span>{" "}
                  <label>
                    {" "}
                    assessing and processing applications, instructions or
                    requests from the Borrower or our customers;
                  </label>{" "}
                  <br />
                  <span> 28.2.3</span>{" "}
                  <label>
                    {" "}
                    communicating with the Borrower and Obligors, including
                    providing the updates on changes to products, services and
                    financing facilities (whether made available by or through
                    Lender) including any additions, expansions, suspensions and
                    replacements of or to such products, services and facilities
                    and their terms and conditions;
                  </label>{" "}
                  <br />
                  <span> 28.2.4</span>{" "}
                  <label>
                    managing infrastructure and business operations and
                    complying with internal policies and procedures;
                  </label>{" "}
                  <br />
                  <span> 28.2.5</span>{" "}
                  <label> responding to queries or feedback;</label> <br />
                  <span> 28.2.6</span>{" "}
                  <label>
                    {" "}
                    addressing or investigating any complaints, claims or
                    disputes;
                  </label>{" "}
                  <br />
                  <span> 28.2.7</span>{" "}
                  <label>
                    {" "}
                    verifying identity for the purposes of providing financing
                    facilities, products or services;
                  </label>{" "}
                  <br />
                  <span> 28.2.8</span>{" "}
                  <label>
                    conducting credit checks, screenings or due diligence checks
                    as may be required under applicable law, regulation or
                    directive;
                  </label>{" "}
                  <br />
                  <span> 28.2.9</span>{" "}
                  <label>
                    {" "}
                    complying with all applicable laws, regulations, rules,
                    directives, orders, instructions and requests from any local
                    or foreign authorities, including regulatory, governmental,
                    tax and law enforcement authorities or other authorities;
                  </label>{" "}
                  <br />
                  <span> 28.2.10</span>{" "}
                  <label>
                    {" "}
                    monitoring products and services provided by or made
                    available through us;
                  </label>{" "}
                  <br />
                  <span> 28.2.11</span>{" "}
                  <label>
                    {" "}
                    complying with obligations and requirements imposed by
                    Lender from time to time by any credit bureau or credit
                    information sharing services of which we are a member or
                    subscriber;
                  </label>{" "}
                  <br />
                  <span> 28.2.12</span>{" "}
                  <label>
                    creating and maintaining credit and risk related models;
                  </label>{" "}
                  <br />
                  <span> 28.2.13</span>{" "}
                  <label>
                    {" "}
                    financial reporting, regulatory reporting, management
                    reporting, risk management (including monitoring credit
                    exposures), audit and record keeping purposes;
                  </label>{" "}
                  <br />
                  <span> 28.2.14</span>{" "}
                  <label>
                    {" "}
                    enabling any actual or proposed assignee or transferee,
                    participant or sub-participant of Lender’ rights or
                    obligations to evaluate any proposed transaction;
                  </label>{" "}
                  <br />
                  <span> 28.2.15</span>{" "}
                  <label> enforcing obligations owed to Lender; and/or</label>{" "}
                  <br />
                  <span> 28.2.16</span>{" "}
                  <label>
                    {" "}
                    seeking professional advice, including legal advice.
                  </label>{" "}
                  <br />
                </p>

                <span> 28.3</span>
                <p>
                  {" "}
                  Lender may disclose to any person who derives or may derive
                  rights or obligation under this Agreement or Finance Document
                  (which may be, transferee or assignees) such information about
                  theObligors.
                </p>

                <p>
                  <span> 28.4</span> The Obligors acknowledge that the
                  permission under this clause may be used for the purpose of
                  the conduct of any credit checks on the Obligors and no
                  further consent from the Obligors is required for such
                  purposes.
                </p>
              </li>
              <li>
                <h3>VALUATION OF THE PROPERTY OR SECURITY</h3>

                <p>
                  <span> 29.1</span> Lender shall be entitled to carry out an
                  enquiry or valuation or to require the Borrower, Guarantors
                  and/or Security Providers to carry out, at the cost and
                  expense of the Borrower, Guarantors and/or Security Providers,
                  an enquiry, valuation or a revaluation of the Security which
                  is offered by Security Providers as Security to the Lender in
                  respect of the Facility or which is the subject matter of any
                  Security Document by any of the valuers or officers appointed
                  by Lender at any time when Lender deems fit and the Borrower,
                  Guarantors and/or Security Providers shall give or cause to be
                  given to Lender or such officer or valuer such rights, access
                  and entry and such written authorities as may be required.
                </p>
                <p>
                  <span> 29.2</span> If the valuation report confirms that the
                  open market value or any other value of the Security is
                  insufficient security to the Facility, Lender shall be
                  entitled at its sole discretion to revise the Facility
                  accordingly and impose any further conditions.
                </p>

                <p>
                  <span> 29.3</span> Any determination of Lender as to the value
                  of or attributable to Security pursuant to clause shall be
                  binding and conclusive on all parties.
                </p>
              </li>
              <li>
                <h3>TIME</h3>
                <p>Time shall be the essence of this Agreement.</p>
              </li>
              <li>
                <h3>NOTICES</h3>
                <span> 31.1</span>
                <label>
                  {" "}
                  Any notice, communication or demand in connection with this
                  Agreement shall be in writing and shall be delivered
                  personally, or by post or facsimile or electronic mail to the
                  addresses or electronic mail addresses set out in this
                  Agreement/Letter of Offer or at facsimile numbers or at such
                  other e-mail addresses or addresses or facsimile number as the
                  recipient may have notified to the other Party hereto in
                  writing.
                </label>
                <br />
                <br /> <span> 31.2</span>
                <label>
                  {" "}
                  Any notice served or given to the Borrower (whether addressed
                  to the Borrower or to any other Obligor) at the address
                  provided in this Agreement / Letter of Offer, shall be deemed
                  to be legally and lawfully served or given to each of the
                  Obligor and the Borrower acts an agent of the Obligor in this
                  regard. <br />
                </label>{" "}
                <br />
                <span> 31.3</span>
                <label>
                  Any notice, communication or demand given as provided in this
                  Clause shall be deemed received by the party to whom it is
                  addressed: <br />
                  <br /> <span> 31.3.1</span>{" "}
                  <label> if delivered by hand, when so delivered;</label>{" "}
                  <br />
                  <span> 31.3.2</span>{" "}
                  <label>
                    {" "}
                    if sent by pre-paid post, on the third (3rd) Business Day
                    after posting;
                  </label>{" "}
                  <br />
                  <span> 31.3.3</span>{" "}
                  <label>
                    if by facsimile, upon the issue of the sender of a
                    transmission control or the like report from the despatching
                    facsimile machine which shows the relevant number of pages
                    comprised in the notice to have been sent if such report is
                    issued on or before 3.00pm on a Business Day, or on the next
                    Business Day if such report is issued after 3.00pm.; or
                  </label>{" "}
                  <br />
                  <span> 31.3.4</span>{" "}
                  <label>
                    {" "}
                    if by electronic mail, on the Business Day of the
                    transmission or the sending of the said electronic e-mail,
                    or on a day which is not a Business Day, it shall be deemed
                    received on the following BusinessDay.
                  </label>{" "}
                  <br />
                </label>
                <p>
                  <br /> <span> 31.4</span> Where there are two (2) or more
                  persons comprised in the expression “Guarantor(s)”: <br />
                  <br /> <span> 31.4.1</span>{" "}
                  <label>
                    {" "}
                    any certificate, statement, notice, demand or other
                    communication given or required to be given to either the
                    Guarantor(s) hereunder and/or in respect of the Facility
                    which is given by Lender to any one or more of such persons
                    shall be deemed to be given to and shall be binding on all
                    such persons as though such certificate and statement,
                    notice, demand or other communication had been given to all
                    such persons; and
                  </label>{" "}
                  <br />
                  <span> 31.4.2</span>{" "}
                  <label>
                    {" "}
                    Lender shall be entitled (but not bound) to treat any
                    certificate, statement, notice, demand or other
                    communication given or required to be given to Lender by any
                    one or more of such persons as having been given by all such
                    persons whereupon such certificate, statement, notice,
                    demand or other communication shall be deemed to have been
                    given by and shall be binding on all such persons as though
                    such certificate, statement, notice, demand or other
                    communication had been given by all such persons;
                  </label>{" "}
                  <br />
                  <p>
                    and the Borrower and Guarantors hereby confirms and declares
                    that each such person is individually authorised to receive
                    and/or give any and all such certificates, statements,
                    notice, demands or other communications from and/or to
                    Lender on behalf of such Persons.
                  </p>
                </p>
                <p>
                  <span> 31.5</span> Lender shall be entitled to rely upon and
                  act on the instructions of the Borrower whether oral or
                  written and whether given by telephone, post, telex, cable,
                  facsimile transmissions, electronic mail or other electronic
                  means. Without prejudice to the generality of the foregoing,
                  Lender shall be entitled to rely and act on any notice or
                  instructions given whether based on signatures (ink or
                  electronic) which appear to Lender, by reference to the names,
                  addresses, e-mail addresses, mobile number, signatures (ink or
                  electronic) of such persons filed with Lender to be the
                  signatures of: <br />
                  <br /> <span> 31.5.1</span> <label>the Borrower; or</label>
                  <br />
                  <span> 31.5.2</span> <label> the Borrower; or</label> <br />
                  <p>
                    whatsoever on behalf of the Borrower, without enquiry on the
                    part of Lender as to the identity of the person giving or
                    purporting to give such notices or instructions or as to the
                    authenticity of such notices or instruction notwithstanding
                    that it is subsequently shown that the same was not given by
                    or on behalf of the Borrower and Lender’ rights under this
                    Agreement shall not be affected by any misuse or
                    unauthorised use of such communication. The risk of the
                    notices or instructions being given by unauthorised persons,
                    any misunderstanding or any error, loss or delay resulting
                    from the use of telephone, postal services, telex, cables
                    devices, facsimile transmission, electronic mail or other
                    electronic means are entirely the risk of the Borrower.
                  </p>
                </p>
              </li>
              <li>
                <h3>SERVICE OF LEGAL PROCESS</h3>
                <span> 32.1</span>
                <label>
                  {" "}
                  The service of any legal process pursuant to any rules court
                  of competent jurisdiction may be given by prepaid registered
                  or ordinary post addressed to the Obligors or each of the
                  Obligor at his address specified herein or at the last known
                  place of business or registered address and such legal process
                  shall be deemed to have been duly served on the third (3rd)
                  Business Day following that on which it is posted,
                  notwithstanding that the said demand or legal process may
                  subsequently be returned undelivered by the postal authorities
                  and if delivered by hand, on the day it was delivered.
                </label>{" "}
                <br />
                <br /> <span> 32.2</span>
                <label>
                  <span>“Legal Process”</span> shall mean all forms of
                  originating process, pleadings, interlocutory applications of
                  whatever nature, affidavits, orders and such documents, other
                  than the aforesaid, which are required to be served under any
                  legislation or subsidiary legislation or by the terms of
                  thisAgreement.
                </label>
                <p>
                  <br /> <span> 32.3</span> No change in the address for Service
                  howsoever brought about shall be effective or binding on
                  Lender unless the Borrower or Guarantor has given to Lender
                  actual notice of the change of the address and nothing done in
                  reliance on Clause 32.1 above shall be affected or prejudiced
                  by any subsequent change in the address for which Lender has
                  no actual knowledge of at the time the act or thing was done
                  or carried out.
                </p>
              </li>
              <li>
                <h3>SEVERABILITY</h3>
                <p>
                  Any term, condition, stipulation, provision, covenant or
                  undertaking contained herein which is illegal, void,
                  prohibited or unenforceable in any jurisdiction shall, as to
                  such jurisdiction, be ineffective to the extent of such
                  illegality, prohibition or unenforceability without
                  invalidating the remaining provisions hereof and any such
                  illegality, prohibition or unenforceability in any
                  jurisdiction shall not invalidate or render illegal, void or
                  unenforceable any such term, condition, stipulation or
                  undertaking in any other jurisdiction.
                </p>
              </li>
              <li>
                <h3>VARIATION OF TERMS</h3>
                <p>
                  It is hereby expressly agreed and declared by the parties
                  hereto that notwithstanding any of the provisions of this
                  Agreement to the contrary, the provisions and terms of this
                  Agreement may at any time be varied or amended by Lender by
                  means of exchange of letters or electronic mails or such other
                  means which may include the signing in electronic form as
                  Lender deems fit from time to time and thereupon such
                  amendments and variations shall be deemed to become effective
                  and the relevant provisions of this Agreement shall be deemed
                  to have been amended or varied accordingly and shall be read
                  and construed as if such amendments and variations have been
                  incorporated in and had formed part of this Agreement.
                </p>
              </li>
              <li>
                <h3>TERMS OF LETTER OF OFFER INCORPORATED</h3>
                <p>
                  The Letter of Offer which has been duly accepted by the
                  Borrower shall form part of this Agreement. Unless otherwise
                  stated, all references, terms, conditions and provisions as
                  set out in the Letter of Offer which are not specifically
                  stated in this Agreement are incorporated herein whether or
                  not repeated herein and shall form part of this Agreement and
                  shall be binding upon the parties hereto and their
                  successor-in-title and assigns of and the Lender and
                  successors in title of the Borrower. Subject to such
                  alterations or variations where necessary to make the
                  provisions of the Letter of Offer consistent with the
                  provisions of this Agreement, in the event of any conflict or
                  discrepancy between the provisions of the Letter of Offer and
                  this Agreement, the provisions of the Letter of Offer shall
                  prevail for the purpose of interpretation and enforcement of
                  this Agreement.
                </p>
              </li>
              <li>
                <h3>COUNTERPARTS AND EXECUTION</h3>
                <p>
                  This Agreement may be executed in any number of counterparts
                  and by the Parties on separate counterparts, each of which
                  when so executed shall be an original, but all counterparts
                  shall together constitute the same document. For the avoidance
                  of doubt, the Obligors confirm and warrant that they are
                  authorised to execute this Agreement and Finance Documents by
                  way of e- signatures in compliance with the Electronic
                  Transactions Act and such other legislations governing
                  e-signatures.
                </p>
              </li>
              <li>
                <h3>NO INFERENCE OF CONDONATION OR ACQUIESCENCE ON LENDER</h3>
                <p>
                  Notwithstanding the fact that Lender may not have exercised
                  any remedy available to it immediately on default by the
                  Borrower or Guarantor(s) or that it may have accepted monies
                  from the Borrower or any of the other Obligors after such
                  default, Lender shall not be held to have condoned or
                  acquiesced in such default and may at any time thereafter
                  exercise all or any of the remedies available and any delay in
                  taking steps to enforce the remedies conferred on or available
                  to it by this Agreement and the Finance Documents or statute
                  shall not be held to prejudice its right of action in respect
                  there of.
                </p>
              </li>
              <li>
                <h3>SUCCESSORS BOUND</h3>
                <p>
                  This Agreement shall be binding upon the successors-in-title
                  of the Borrower and Guarantor(s) and on the successors-
                  in-title and assignees of Lender.
                </p>
              </li>
              <li>
                <h3>INVOLUNTARY LOSS</h3>
                <p>
                  the Lender shall not be answerable for any involuntary loss
                  happening in or about the exercise or execution of any power,
                  right, privilege and remedy conferred on the Lender by this
                  Agreement or Finance Document or by law.
                </p>
              </li>
              <li>
                <h3>REMEDIES</h3>
                <p>
                  No remedy conferred by any of the provisions of this Agreement
                  to the Lender against the Borrower and the Guarantors is
                  intended to be exclusive of any other remedy which is
                  otherwise available at law, in equity, by statute, through
                  Security Documents or otherwise, and each and every other
                  remedy thereto shall be cumulative and shall be in addition to
                  every other remedy of this Agreement. The election of any one
                  or more of such remedies by the Lender against the Borrower
                  and the Guarantors hereto shall not constitute a waiver of the
                  right to pursue any other available remedies against the
                  Borrower and the Guarantors.
                </p>
              </li>
              <li>
                <h3>GOVERNING LAW</h3>
                <span> 41.1</span>
                <label>
                  This Agreement shall be governed by and shall be construed in
                  all respects in accordance with the laws of Republic of
                  Singapore and in relation to any legal action or proceedings
                  arising out of or in connection with this Agreement
                  (“Proceedings”) and the parties herein hereby submit to the
                  non-exclusive jurisdiction of the courts of the Republic of
                  Singapore and waives any objections to proceedings in any
                  court on the grounds that the Proceedings have been brought in
                  an inconvenient forum.
                </label>
                <p>
                  <br /> <span> 41.2</span> Such submission however shall not
                  affect the right of Lender to take Proceedings in any other
                  jurisdiction (where necessary) nor shall the taking of
                  Proceedings in any jurisdiction preclude Lender from taking
                  Proceedings in any other jurisdiction and Lender shall be at
                  liberty to initiate and take actions or Proceedings or
                  otherwise in the court of Republic of Singapore and/or
                  elsewhere as it may deemfit.
                </p>
              </li>
              <li>
                <h3>INDEPENDENT LEGAL ADVICE</h3>
                <span> 42.1</span>
                <label>
                  {" "}
                  The Borrower, Guarantors or Obligors acknowledge having read
                  this Agreement and Guarantee and all Finance Documents which
                  relates to this Agreement before signing it and declare that
                  they fully understand the terms, conditions and undertakings
                  contained herein.
                </label>

                <p>
                  <br /> <span> 42.2</span> The Borrower, Guarantors or Obligors
                  further acknowledge that this Agreement and Guarantee and all
                  Finance Documents which relates to this Agreement have been
                  signed voluntarily and without duress or under influence
                  exercised by Lender or by any other person or persons upon
                  them and they agree that the terms are reasonable, fair and
                  equitable. The Borrower, Guarantors and Obligors confirm and
                  acknowledge that they have obtained or have had opportunity to
                  obtain independent legal advice with regard to the contents of
                  this Agreement, Guarantee or the other Finance Documents which
                  this Agreement relates.
                </p>
              </li>
              <li>
                <h3>WAIVER OF IMMUNITY AND CONSENT TO RELIEF</h3>
                <span> 43.1</span>
                <label>
                  {" "}
                  To the extent that Obligors may in any jurisdiction claim for
                  themselves or their assets immunity from suit, execution,
                  attachment (whether in aid of execution, before judgment or
                  otherwise) or other legal process and to the extent in any
                  such jurisdiction there may be attributed to themselves or
                  their assets such immunity whether on grounds of sovereignty
                  or otherwise (whether or not claimed), Obligors irrevocably
                  agree not to claim and irrevocably waive such immunity to the
                  full extent permitted by the laws of such jurisdiction.
                  Obligors irrevocably agree and undertake that they and their
                  assets are, and shall be subject to any proceedings attachment
                  or execution in respect of their obligations under this
                  Agreement, Finance Documents or Guarantee.
                </label>
                <p>
                  <br /> <span> 43.2</span> The Obligors irrevocably consent in
                  respect of any proceedings anywhere to the giving of any
                  relief or the issue of any process in connection with those
                  proceedings including, without limitation, the making,
                  enforcement or execution against any assets whatsoever
                  (irrespective of the use or intended use) of any order or
                  judgment which may be made or given in those proceedings.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
