// linkedIn
export const linkedIn = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.35782 1.67975C3.3576 2.12502 3.1805 2.55197 2.86548 2.86667C2.55047 3.18137 2.12334 3.35804 1.67807 3.35782C1.2328 3.3576 0.805847 3.1805 0.491147 2.86548C0.176448 2.55047 -0.000222427 2.12334 2.10167e-07 1.67807C0.000222847 1.2328 0.177321 0.805847 0.492335 0.491147C0.807349 0.176448 1.23447 -0.000222427 1.67975 2.10167e-07C2.12502 0.000222847 2.55197 0.177321 2.86667 0.492335C3.18137 0.807349 3.35804 1.23447 3.35782 1.67975ZM3.40819 4.60105H0.0503674V15.111H3.40819V4.60105ZM8.71354 4.60105H5.37251V15.111H8.67996V9.5958C8.67996 6.5234 12.6842 6.23799 12.6842 9.5958V15.111H16V8.45414C16 3.27471 10.0735 3.46779 8.67996 6.01133L8.71354 4.60105Z"
      fill="#2E173B"
    />
  </svg>
);

// youTube
export const youTube = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4 8L10.552 5.6L6.4 3.2V8ZM15.648 1.736C15.752 2.112 15.824 2.616 15.872 3.256C15.928 3.896 15.952 4.448 15.952 4.928L16 5.6C16 7.352 15.872 8.64 15.648 9.464C15.448 10.184 14.984 10.648 14.264 10.848C13.888 10.952 13.2 11.024 12.144 11.072C11.104 11.128 10.152 11.152 9.272 11.152L8 11.2C4.648 11.2 2.56 11.072 1.736 10.848C1.016 10.648 0.552 10.184 0.352 9.464C0.248 9.088 0.176 8.584 0.128 7.944C0.0720001 7.304 0.048 6.752 0.048 6.272L0 5.6C0 3.848 0.128 2.56 0.352 1.736C0.552 1.016 1.016 0.552 1.736 0.352C2.112 0.248 2.8 0.176 3.856 0.128C4.896 0.0719999 5.848 0.048 6.728 0.048L8 0C11.352 0 13.44 0.128 14.264 0.352C14.984 0.552 15.448 1.016 15.648 1.736Z"
      fill="#2E173B"
    />
  </svg>
);

// twitter
export const twitter = () => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.026 13.0007C11.064 13.0007 14.367 7.99769 14.367 3.66669C14.367 3.52669 14.367 3.38469 14.361 3.24469C15.0041 2.77918 15.5591 2.20284 16 1.54269C15.3993 1.80754 14.7628 1.9821 14.111 2.06069C14.7975 1.65033 15.3117 1.00466 15.558 0.243693C14.913 0.625817 14.207 0.894067 13.471 1.03669C12.9762 0.509717 12.3214 0.160611 11.6081 0.0434506C10.8948 -0.0737096 10.1627 0.0476146 9.52534 0.38863C8.88796 0.729646 8.38081 1.27132 8.08245 1.92975C7.78409 2.58818 7.71118 3.32663 7.875 4.03069C6.56974 3.96524 5.29282 3.62616 4.12704 3.03544C2.96127 2.44471 1.93268 1.61554 1.108 0.601693C0.68934 1.32478 0.561574 2.18009 0.750646 2.99396C0.939718 3.80782 1.43145 4.51922 2.126 4.98369C1.60554 4.96601 1.09652 4.82625 0.64 4.57569V4.62069C0.640897 5.37817 0.903307 6.11211 1.38287 6.69845C1.86244 7.28479 2.52975 7.68757 3.272 7.83869C2.99026 7.91631 2.69923 7.955 2.407 7.95369C2.20098 7.95432 1.99538 7.93523 1.793 7.89669C2.00279 8.54875 2.41127 9.11886 2.96125 9.52717C3.51122 9.93548 4.17513 10.1615 4.86 10.1737C3.69656 11.0875 2.2594 11.5831 0.78 11.5807C0.519321 11.5818 0.258823 11.5668 0 11.5357C1.50151 12.493 3.2453 13.0012 5.026 13.0007Z"
      fill="#2E173B"
    />
  </svg>
);

// instaGram
export const instaGram = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.651 2.96057C12.4368 2.96057 12.2626 3.13485 12.2626 3.34901C12.2626 3.56316 12.4368 3.73745 12.651 3.73745C12.8652 3.73745 13.0394 3.5632 13.0394 3.34901C13.0394 3.13482 12.8652 2.96057 12.651 2.96057ZM8.00002 4.55635C6.10121 4.55635 4.5564 6.10116 4.5564 7.99998C4.5564 9.89879 6.10121 11.4436 8.00002 11.4436C9.89886 11.4436 11.4437 9.89882 11.4437 8.00001C11.4437 6.1012 9.89886 4.55635 8.00002 4.55635Z"
      fill="#2E173B"
    />
    <path
      d="M11.6138 0H4.38616C1.96762 0 0 1.96763 0 4.38619V11.6138C0 14.0324 1.96762 16 4.38616 16H11.6138C14.0324 16 16 14.0324 16 11.6138V4.38619C16 1.96763 14.0324 0 11.6138 0ZM8 12.3885C5.58016 12.3885 3.61153 10.4198 3.61153 8C3.61153 5.58016 5.58019 3.61156 8 3.61156C10.4198 3.61156 12.3885 5.58019 12.3885 8C12.3885 10.4198 10.4198 12.3885 8 12.3885ZM12.651 4.68237C11.9158 4.68237 11.3177 4.08425 11.3177 3.34906C11.3177 2.61387 11.9158 2.01572 12.651 2.01572C13.3862 2.01572 13.9843 2.61384 13.9843 3.34903C13.9843 4.08422 13.3862 4.68237 12.651 4.68237Z"
      fill="#2E173B"
    />
  </svg>
);
