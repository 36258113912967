// import { Link, NavLink } from "react-router-dom";
import "containers/PrivacyPolicy/privacyPolicy.scss";
import { privacyPolicy } from "constant/privacyPolicy";

const PrivacyPolicy = () => {
  return (
    <section className="privacyPolicy">
      <div className="container privacyPolicyContainer">
        <div className="commonHeading privacyPolicyCommonHeading">
          <h1>Privacy Policy</h1>
        </div>
        <div className="privacyPolicyContent">
          {privacyPolicy.map((policy, index) => (
            <div className="privacyPolicyWrapper" key={index}>
              <div className="privacyPolicyLabel">{policy?.title}</div>

              {policy?.subData?.map((data, index) => (
                <div className="privacyPolicyDescription" key={index}>
                  {data?.paragraph && <p></p>}

                  {data?.subTitle && data?.subDescription && (
                    <ol>
                      <li className="privacyPolicyListData">
                        <span className="underline">{data?.subTitle}</span>{" "}
                        <br />
                        {Array.isArray(data?.subDescription) ? (
                          data.subDescription.map((item, index) => (
                            <div key={index}>
                              {data?.subTitle === "3.3 Tracking Cookies Data" &&
                                (index === 1 || index === 3) && <br />}
                              {data?.subTitle !== "3.3 Tracking Cookies Data" &&
                                index !== 0 && <br />}
                              {item}
                            </div>
                          ))
                        ) : (
                          <span>{data?.subDescription}</span>
                        )}
                      </li>

                      {data?.subListData && (
                        <>
                          <br />
                          <ul className="privacyPolicyDescriptionList">
                            {data.subListData.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </>
                      )}

                      {data?.paragraph && (
                        <>
                          <br />
                          <p>{data?.paragraph}</p>
                        </>
                      )}
                    </ol>
                  )}

                  {data?.subPara && (
                    <>
                      <ol>
                        <li className="privacyPolicyListData">
                          {data?.subPara}
                        </li>
                      </ol>

                      <br />
                      <ul className="privacyPolicyDescriptionList">
                        {data?.subListData?.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}

                  {data?.subParagraph && (
                    <>
                      {data?.subParagraph.map((data, index) => (
                        <span key={index}>
                          {index !== 0 && <br />}
                          {data}
                          <br />
                        </span>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
